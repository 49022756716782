// TODO: https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { isBrowser } from 'utils/auth';
import { ReactFlowProvider } from 'react-flow-renderer';
import { store } from './configureStore';
import { startUp } from './App/slice';

const App = ({ children }) => {
  if (isBrowser) {
    store.dispatch(startUp());
  }

  return <>{children}</>;
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => (
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  <Provider store={store}>
    <ReactFlowProvider>
      <App>{element}</App>
    </ReactFlowProvider>
  </Provider>
);

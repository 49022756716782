import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  call,
  put,
  takeLatest,
  fork,
  take,
  actionChannel,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { getIdToken, signInSuccess, signOutSuccess } from 'redux/Auth/slice';
import { runJob } from 'redux/Jobs/slice';
import {
  disconnectedDevice,
  watchWebSocket,
  getConnectedDevices,
  setClientStatus,
  getConnectedDevice,
} from './slice';
import { receiveDeviceResult } from '../DeviceResults/slice';
import { receiveTestCaseResult } from '../TestCaseResults/slice';

const CLIENT_STATUS = 'client-status';
const DEVICE_CONNECTED = 'device-connected';
const DEVICE_DISCONNECTED = 'device-disconnected';
const DEVICES_CONNECTED = 'devices-connected';
const JOB_RUN = 'job-run';
const DEVICE_RESULT_CREATED = 'device-result-created';
const DEVICE_RESULT_UPDATED = 'device-result-updated';
const TEST_CASE_RESULT_CREATED = 'test-case-result-created';
const TEST_CASE_RESULT_UPDATED = 'test-case-result-updated';

// this function creates an event channel from a given socket
function createSocketChannel(ws) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel((emit) => {
    // setup the subscription
    ws.onmessage = ({ data }) => {
      // puts event payload into the channel
      // this allows a Saga to take this payload from the returned channel
      emit(JSON.parse(data));
    };

    return () => {
      // Do nothing
    };
  });
}

const createWebSocketPromise = () => {
  // async url provider
  const urlProvider = async () => {
    const idToken = await getIdToken();
    return `${process.env.GATSBY_WEB_SOCKET}?Authorization=${idToken}&type=web`;
  };

  return new Promise((resolve) => {
    const ws = new ReconnectingWebSocket(urlProvider);

    ws.onopen = () => {
      console.log(`[GENERAL WS][CONNECTED]`);
      resolve(ws);
    };

    ws.onerror = (event) => {
      console.error(
        '[GENERAL WS][ERROR] API Gateway WebSocket error observed:',
        event
      );
      // Do not reject to wait until resolved
    };

    ws.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[GENERAL WS][CLOSED] API Gateway Websocket Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log(
          `[GENERAL WS][CLOSED] API Gateway Websocket Connection died, code=${event.code} reason=${event.reason}`
        );
      }
    };
  });
};

/**
 * Request to socket server after connect to socket succeed
 */
function requestAfterConnect(ws) {
  // TODO: Get status of all connected clients
  ws.send(
    JSON.stringify({
      action: CLIENT_STATUS,
    })
  );
}

function* handleReceiveMessage(wsChannel) {
  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const { action, payload } = yield take(wsChannel);
      switch (action) {
        case CLIENT_STATUS:
          yield put(setClientStatus(payload));
          break;
        case DEVICE_CONNECTED:
          yield put(getConnectedDevice(payload));
          break;
        case DEVICE_DISCONNECTED:
          yield put(disconnectedDevice(payload));
          break;
        case DEVICES_CONNECTED:
          yield put(getConnectedDevices(payload));
          break;
        case DEVICE_RESULT_CREATED:
        case DEVICE_RESULT_UPDATED:
          yield put(receiveDeviceResult(payload));
          break;
        case TEST_CASE_RESULT_CREATED:
        case TEST_CASE_RESULT_UPDATED:
          yield put(receiveTestCaseResult(payload));
          break;
        default:
          console.log('WS Message:');
          console.log('+ Action:', action);
          console.log('+ Payload:', payload);
          break;
      }
    } catch (err) {
      console.log('handleReceiveMessage err', err);
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      wsChannel.close();
    }
  }
}

function* sendJobRun(socket) {
  while (true) {
    const { payload } = yield take(runJob);
    const { clientId, jobId } = payload;
    socket.send(
      JSON.stringify({
        action: JOB_RUN,
        payload: { clientId, jobId },
      })
    );
  }
}

function* handleSignedInOut(ws) {
  const requestChan = yield actionChannel([signInSuccess, signOutSuccess]);
  while (true) {
    yield take(requestChan);

    ws.reconnect();

    requestAfterConnect(ws);
  }
}

function* sendMessage(ws) {
  yield fork(sendJobRun, ws);
  yield fork(handleSignedInOut, ws);
}

function* attemptConnect() {
  const socket = yield call(createWebSocketPromise);

  requestAfterConnect(socket);

  const socketChannel = yield call(createSocketChannel, socket);

  yield fork(handleReceiveMessage, socketChannel);

  yield fork(sendMessage, socket);
}

function* watchWebSocketWorker() {
  // TODO: Create web socket, socket channel and handle receive messages
  yield fork(attemptConnect);
}

export default [takeLatest(watchWebSocket, watchWebSocketWorker)];

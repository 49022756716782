import { takeLatest, call, put } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as FileActions from './slice';
import * as APIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

// TODO: Create new file
export function* createFileWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, APIs.createFile, payload);
    yield put(FileActions.createFileSuccess(data));
  } catch (err) {
    yield put(FileActions.createFileFailure(err));
    showError(err);
  }
}

// TODO: Get list files
export function* getFilesWorker({ payload }) {
  try {
    const response = yield call(apiCaller, APIs.getFiles, payload);
    const result = convertGetAllData(response.data);
    yield put(
      FileActions.getFilesSuccess({ ...result, $refresh: payload.$refresh })
    );
  } catch (err) {
    yield put(FileActions.getFilesFailure(err));
    showError(err);
  }
}

// TODO: Get file detail
export function* getFileWorker({ payload, [DEFERRED]: { resolve, reject } }) {
  try {
    const { data } = yield call(apiCaller, APIs.getFile, payload);
    yield put(FileActions.getFileDetailSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(FileActions.getFileDetailFailure(err));
    showError(err);
    reject(err);
  }
}

// TODO: Update file
export function* updateFileWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, APIs.updateFile, payload);
    yield put(FileActions.updateFileSuccess(data));
  } catch (err) {
    yield put(FileActions.updateFileFailure(err));
    showError(err);
  }
}

export function* deleteFileWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, APIs.deleteFile, payload);
    yield put(FileActions.deleteFileSuccess(data));
  } catch (err) {
    yield put(FileActions.deleteFileFailure(err));
    showError(err);
  }
}

export default [
  takeLatest(FileActions.createFile, createFileWorker),
  takeLatest(FileActions.getFiles, getFilesWorker),
  takeLatest(FileActions.getFileDetail, getFileWorker),
  takeLatest(FileActions.updateFile, updateFileWorker),
  takeLatest(FileActions.deleteFile, deleteFileWorker),
];

import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ws',
  initialState: {},
  reducers: {
    watchLocalWebSocket() {
      // Dispatch to saga
    },
    setLocalClientInfo() {
      // Used in other reducer
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const { watchLocalWebSocket, setLocalClientInfo } = actions;

export default reducer;

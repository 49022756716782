// import omit from 'lodash/omit';
// import formatJSON from 'format-json';

import { CHANGE_LANGUAGE } from './actions';

const INITIAL_STATE = {
  language: {
    code: 'en',
    readable: 'English',
  },
};

export default function shared(state = INITIAL_STATE, action) {
  const { language } = action;

  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language,
      };
    default:
      return state;
  }
}

import { useState } from 'react';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import isEqual from 'react-fast-compare';

const { keyBy, cloneDeep } = require('lodash');
const {
  isEdge,
  isNode,
  addEdge,
  removeElements,
  updateEdge,
} = require('react-flow-renderer');

/**
 * Convert action flow to recorded action
 * @param {String} platformName: 'ios' or 'android'
 * @param {Object[]} elements: Pure elements of Action flow (without function and element data)
 * @param {Object} componentsData: componentsData
 */
export const convertPureElements = (
  platformName,
  pureElements,
  componentsData
) => {
  if (!pureElements || !pureElements.length) {
    return [];
  }
  const isIOS = platformName === 'ios';
  const isAndroid = platformName === 'android';

  const edges = pureElements.filter((action) => isEdge(action));
  const nodes = pureElements.filter((action) => isNode(action));
  const nodeData = keyBy(nodes, 'id');

  const sources = keyBy(edges, 'source');

  // Get flow from start to end
  const flowIds = [];

  const recursive = (node = {}) => {
    const { target } = node;
    // Terminate
    if (!target) return;
    if (target === 'end') return;
    if (!sources[target]) return;
    flowIds.push(target);
    // Recursive
    return recursive(sources[target]);
  };

  recursive(sources.start);

  return flowIds.map((flowId) => {
    const node = nodeData[flowId];

    const { id: key, type, data, position } = node;

    switch (type) {
      case 'FindElementNode': {
        const { strategy, selector, variable, name } = componentsData[
          data.componentId
        ];
        return {
          key,
          action: 'findAndAssign',
          params: [strategy, selector, variable, false],
          name,
          position,
        };
      }
      case 'ScrollAndFindNode': {
        const {
          variable,
          criteria,
          selectorValue,
          selectorVariable,
          maxTries,
          name,
        } = componentsData[data.componentId];
        return {
          key,
          action: 'scrollAndFindUntilVisible',
          params: [
            variable,
            null,
            {
              criteria,
              value: selectorValue, // TODO: value used before
              selectorVariable,
              maxTries,
            },
          ],
          name,
          position,
        };
      }
      case 'SendKeysNode': {
        const {
          componentId,
          sendKeysVariable,
          sendKeysValue,
          sendKeysAction,
        } = data;

        const { variable } = componentsData[componentId];

        return {
          key,
          action: 'sendKeys',
          params: [
            variable,
            null,
            sendKeysValue,
            sendKeysVariable,
            sendKeysAction,
          ],
          position,
        };
      }
      case 'ClickNode': {
        const { componentId } = data;
        const { variable } = componentsData[componentId];

        return {
          key,
          action: 'click',
          params: [variable, null],
          position,
        };
      }
      case 'ClearNode': {
        const { componentId } = data;
        const { variable } = componentsData[componentId];

        return {
          key,
          action: 'clear',
          params: [variable, null],
          position,
        };
      }
      case 'AssertNode': {
        const { componentId, assertType, assertValue, assertVariable } = data;
        const { variable } = componentsData[componentId];

        return {
          key,
          action: 'text',
          params: [variable, null, assertValue, assertVariable, assertType],
          position,
        };
      }
      case 'SwipeByTimesNode': {
        const { startX, startY, endX, endY, maxSwipe } = data;
        return {
          key,
          action: 'scrollWithSpecificCoordinates',
          params: [
            null,
            null,
            {
              start: {
                x: startX,
                y: startY,
              },
              end: {
                x: endX,
                y: endY,
              },
              maxSwipe,
            },
          ],
          position,
        };
      }
      case 'TapNode': {
        const { x, y } = data;
        return {
          key,
          action: 'tap',
          params: [null, null, x, y],
          position,
        };
      }
      case 'SleepNode': {
        const { seconds } = data;
        return {
          key,
          action: 'sleep',
          params: [seconds],
          position,
        };
      }
      case 'BackNode': {
        return {
          key,
          action: 'back',
          params: [],
          position,
        };
      }
      case 'LongPressNode': {
        const { componentId, seconds } = data;
        const { variable } = componentsData[componentId];

        return {
          key,
          action: 'longPress',
          params: [variable, null, seconds],
          position,
        };
      }
      case 'PressPhysicalButtonNode': {
        const { buttonName, times } = data;

        return {
          key,
          action: 'pressPhysicalButton',
          params: [null, null, buttonName, times],
          position,
        };
      }
      case 'SwitchAppNode': {
        const { appName, bundleId, appPackage, appActivity } = data;
        const params = [null, null, appName];

        if (isIOS) {
          params.push(bundleId);
        } else if (isAndroid) {
          params.push(appPackage, appActivity);
        }

        return {
          key,
          action: 'switchApp',
          params,
          position,
        };
      }
      default:
        return {
          key,
          position,
        };
    }
  });
};

/**
 * Get key pair of variable and component id from component data: {el1: '123'}
 * @param {Object} componentsData
 */
export const getVariableComponentIdPair = (componentsData) =>
  Object.entries(componentsData).reduce((output, [key, value]) => {
    if (value.variable) {
      output[value.variable] = key;
    }
    return output;
  }, {});

/**
 * Convert recorded action to node
 * @param {Object} recordedAction
 * @param {Object} componentsData
 * @param {Object} variableStorage
 * @param {Object} previousPosition
 */
export const parseRecordedAction = (
  platformName,
  recordedAction,
  componentsData,
  variableStorage,
  previousPosition
) => {
  const isAndroid = platformName === 'android';
  const isIOS = platformName === 'ios';
  const variableComponentIdPair = getVariableComponentIdPair(componentsData);
  const {
    key: id,
    params,
    action,
    position = {
      x: previousPosition?.x,
      y: previousPosition?.y + 100,
    },
  } = recordedAction;

  switch (action) {
    case 'findAndAssign': {
      const [, , variable] = params;

      return {
        id,
        type: 'FindElementNode',
        data: {
          componentId: variableComponentIdPair[variable],
        },
        position,
      };
    }
    case 'scrollAndFindUntilVisible': {
      const [variable] = params;
      // Check that after that element
      return {
        id,
        type: 'ScrollAndFindNode',
        data: {
          componentId: variableComponentIdPair[variable],
        },
        position,
      };
    }
    case 'sendKeys': {
      const [
        variable,
        ,
        sendKeysValue,
        sendKeysVariable,
        sendKeysAction,
      ] = params;

      return {
        id,
        type: 'SendKeysNode',
        data: {
          componentId: variableComponentIdPair[variable],

          sendKeysVariable,
          sendKeysValue: sendKeysVariable
            ? variableStorage[sendKeysVariable]
            : sendKeysValue,
          sendKeysAction,
        },
        position,
      };
    }
    case 'click': {
      const [variable] = params;

      return {
        id,
        type: 'ClickNode',
        data: {
          componentId: variableComponentIdPair[variable],
        },
        position,
      };
    }
    case 'longPress': {
      const [variable, , seconds] = params;

      return {
        id,
        type: 'LongPressNode',
        data: {
          componentId: variableComponentIdPair[variable],
          seconds,
        },
        position,
      };
    }
    case 'clear': {
      const [variable] = params;

      return {
        id,
        type: 'ClearNode',
        data: {
          componentId: variableComponentIdPair[variable],
        },
        position,
      };
    }
    case 'text': {
      const [variable, , assertValue, assertVariable, assertType] = params;

      return {
        id,
        type: 'AssertNode',
        data: {
          componentId: variableComponentIdPair[variable],

          assertType,
          assertValue,
          assertVariable,
        },
        position,
      };
    }
    case 'scrollWithSpecificCoordinates': {
      const { start, end, maxSwipe } = params[2];
      return {
        id,
        type: 'SwipeByTimesNode',
        data: {
          startX: start.x,
          startY: start.y,
          endX: end.x,
          endY: end.y,
          maxSwipe,
        },
        position,
      };
    }
    case 'tap': {
      const [, , x, y] = params;
      return {
        id,
        type: 'TapNode',
        data: { x, y },
        position,
      };
    }
    case 'sleep': {
      const [seconds] = params;
      return {
        id,
        type: 'SleepNode',
        data: { seconds },
        position,
      };
    }
    case 'back': {
      return {
        id,
        type: 'BackNode',
        data: {},
        position,
      };
    }
    case 'pressPhysicalButton': {
      const [, , buttonName, times] = params;
      return {
        id,
        type: 'PressPhysicalButtonNode',
        data: {
          buttonName,
          times,
        },
        position,
      };
    }
    case 'switchApp': {
      const [, , appName, ...rest] = params;
      const data = { appName };
      if (isIOS) {
        const [bundleId] = rest;
        data.bundleId = bundleId;
      } else if (isAndroid) {
        const [appPackage, appActivity] = rest;
        data.appPackage = appPackage;
        data.appActivity = appActivity;
      }
      return {
        id,
        type: 'SwitchAppNode',
        data,
        position,
      };
    }
    default:
      return { id, data: {}, position };
  }
};

/**
 * Get component from recorded action
 * @param {Object} recordedAction
 * @param {number} index
 */
export const getComponent = (recordedAction, index) => {
  const { key: id, action, params, name } = recordedAction;
  const data = {
    id,
    name: name || `Item ${index + 1}`,
    referenceActions: [], // Id of all actions reference to this component
  };
  if (action === 'findAndAssign') {
    const [strategy, selector, variable] = params;
    return {
      ...data,
      type: 'findAndAssign',
      variable,
      strategy,
      selector,
    };
  }
  if (action === 'scrollAndFindUntilVisible') {
    const [variable, , search] = params;
    const {
      criteria,
      value: selectorValue, // value used before
      maxTries,
      selectorVariable,
    } = search;
    return {
      ...data,
      type: 'scrollAndFindUntilVisible',
      variable,
      criteria,
      selectorValue,
      selectorVariable,
      maxTries,
    };
  }
  return null;
};

/**
 * Get all component from recorded actions
 * @param {*} recordedActions
 */
const getComponentDataFromRecordedActions = (recordedActions) => {
  const components = recordedActions
    .filter(
      ({ action }) =>
        action === 'findAndAssign' || action === 'scrollAndFindUntilVisible'
    )
    .map(getComponent);

  return keyBy(components, 'id');
};

/**
 * Append START and END
 * @param {array} mainElements
 */
const appendStartEndNode = (mainElements) => {
  // TODO: Get position for header and  footer
  const afterStart = mainElements[0];
  const afterStartPosition = afterStart?.position;
  const beforeEnd = mainElements?.[mainElements.length - 1];
  const beforeEndPosition = beforeEnd?.position;

  const startPosition = {
    x: afterStartPosition?.x || 0,
    y: afterStartPosition?.y - 100 || 0,
  };
  const endPosition = {
    x: beforeEndPosition?.x || 0,
    y: beforeEndPosition?.y + 100 || 200,
  };

  const START_NODE = {
    id: 'start',
    type: 'StartNode',
    position: startPosition,
    selectable: false,
  };

  const END_NODE = {
    id: 'end',
    type: 'EndNode',
    position: endPosition,
    selectable: false,
  };

  // TODO: Append header and footer
  if (mainElements.length > 0) {
    return keyBy(
      [
        START_NODE,
        {
          id: `e-start-${afterStart.id}`,
          source: 'start',
          target: afterStart.id,
          arrowHeadType: 'arrowclosed',
        },
        ...mainElements,
        {
          id: `e-${beforeEnd.id}-end`,
          source: beforeEnd.id,
          target: 'end',
          arrowHeadType: 'arrowclosed',
        },
        END_NODE,
      ],
      'id'
    );
  }
  return keyBy(
    [
      START_NODE,
      {
        id: `e-start-end`,
        source: 'start',
        target: 'end',
        arrowHeadType: 'arrowclosed',
      },
      END_NODE,
    ],
    'id'
  );
};

/**
 * Convert action flow to pure action data and component data
 * @param {Object[]} recordedActions: List recorded action
 * @param {string} recordedActions[].id - id
 * @param {string} recordedActions[].action - action name
 * @param {string[]} recordedActions[].params - dynamic parameter
 * @param {Object} variableStorage
 */
export const convertRecordedActions = (recordedActions, variableStorage) => {
  // TODO: Get elements
  const componentsData = getComponentDataFromRecordedActions(recordedActions);

  let previousPosition = { x: 0, y: 0 };

  // TODO: Convert
  const convertedActions = recordedActions.flatMap(
    (recordedAction, index, actions) => {
      const node = parseRecordedAction(
        recordedAction,
        componentsData,
        variableStorage,
        previousPosition
      );
      previousPosition = node.position;

      if (recordedActions.length === index + 1) {
        return [node];
      }
      const source = recordedAction.key;
      const target = actions[index + 1].key;
      return [
        node,
        {
          id: `e-${source}-${target}`,
          source,
          target,
          arrowHeadType: 'arrowclosed',
        },
      ];
    }
  );

  const pureElementsData = appendStartEndNode(convertedActions);

  return {
    componentsData,
    pureElementsData,
  };
};

/**
 * Get elements
 * @param {*} pureElementsData
 * @param {*} componentsData
 */
export const getElements = (
  pureElementsData = {},
  componentsData = {},
  { onUpdateAction, onUpdateComponent, editable = true } = {}
) => {
  // TODO: Get component
  const _componentsData = {};
  Object.entries(componentsData).forEach(([key, value]) => {
    _componentsData[key] = { ...value, onUpdate: onUpdateComponent };
  });

  // TODO: Add function to every node
  return Object.keys(pureElementsData).map((key) => {
    const action = cloneDeep(pureElementsData[key]);
    const { data } = action;

    if (data) {
      // TODO: Update element in each action
      if (data?.componentId) {
        data.element = _componentsData[data.componentId];
      }
      data.onUpdate = onUpdateAction;
      data.editable = editable;
    }

    return action;
  });
};

/**
 * Add custom edge
 * @param {*} Edge edge
 * @param {*} elements elements
 */
export const addCustomEdge = ({ source, target }, elements) => {
  const targetEdges = elements.filter(
    (el) => isEdge(el) && el.target === target
  );

  const sourceEdges = elements.filter(
    (el) => isEdge(el) && el.source === source
  );

  const remainElements = removeElements(
    [...targetEdges, ...sourceEdges],
    elements
  );

  return addEdge(
    {
      source,
      target,
      arrowHeadType: 'arrowclosed',
    },
    remainElements
  );
};

/**
 * Update custom edge
 * @param {*} oldEdge
 * @param {*} newConnection
 * @param {*} elements
 */
export const updateCustomEdge = (oldEdge, newConnection, elements) => {
  let remainElements = elements;

  // In case user drags header of edge
  if (oldEdge.target !== newConnection.target) {
    const targetEdges = elements.filter(
      (el) => isEdge(el) && el.target === newConnection.target
    );
    remainElements = removeElements(targetEdges, elements);
  }

  // In case user drags footer of edge
  if (oldEdge.source !== newConnection.source) {
    const sourceEdges = elements.filter(
      (el) => isEdge(el) && el.source === newConnection.source
    );

    remainElements = removeElements(sourceEdges, elements);
  }
  return updateEdge(oldEdge, newConnection, remainElements);
};

/**
 * Custom remove elements
 * @param {array} elementsToRemove
 * @param {array} elements
 */
export const removeCustomElements = (elementsToRemove, elements) =>
  removeElements(elementsToRemove, elements);

/**
 * Get elements hook
 * @param {Object} pureElementsData
 * @param {Object} componentsData
 * @callback optional.onUpdateAction
 * @callback optional.onUpdateComponent
 * @param {bool} optional.editable
 */

export const useElements = (
  pureElementsData = {},
  componentsData = {},
  optional
) => {
  const [elements, setElements] = useState([]);

  useDeepCompareEffect(() => {
    const newElements = getElements(pureElementsData, componentsData, optional);
    if (!isEqual(newElements, elements)) {
      setElements(newElements);
    }
    // TODO: No need run effect if elements changed
  }, [pureElementsData, componentsData, optional]);

  return elements;
};

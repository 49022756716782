import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

export function convertGetAllData(data, key = 'id') {
  if (isEmpty(data)) {
    return {
      data: {},
      ids: [],
    };
  }
  return {
    data: keyBy(data, key),
    ids: uniq(data && Array.isArray(data) && data.map((item) => item[key])),
  };
}

export function filterObject(obj, predicate) {
  return Object.assign(
    {},
    ...Object.keys(obj)
      .filter((key) => predicate(key, obj[key]))
      .map((key) => ({ [key]: obj[key] }))
  );
}

import { get, post, put, del } from '../utils/api';

export async function getAppleTeams() {
  return get('apple-teams');
}

export async function createAppleTeam(data) {
  return post('apple-teams', data);
}

export async function getAppleTeam(data) {
  return get(`apple-teams/${data.id}`);
}

export async function updateAppleTeam(data) {
  return put(`apple-teams/${data.id}`, data);
}

export async function deleteAppleTeam(data) {
  return del(`apple-teams/${data.id}`, data);
}

import { createSelector } from 'reselect';
import { memoize, uniq } from 'lodash';

const KEY = 'clientDevices';

export const currentPlatformNameSelector = (state) =>
  state[KEY].currentPlatformName;
export const currentDeviceTypeSelector = (state) =>
  state[KEY].currentDeviceType;
export const currentClientIdSelector = (state) => state[KEY].currentClientId;
export const selectedDeviceSelector = (state) => state[KEY].selectedDevice;

const clientDataSelector = (state) => state[KEY].clientData;
const clientIdsSelector = (state) => state[KEY].clientIds;

export const clientsSelector = createSelector(
  clientDataSelector,
  clientIdsSelector,
  (data, ids) => ids.map((id) => data[id])
);

export const clientStatusFilterSelector = createSelector(
  (state) => state[KEY].clientData,
  (clientData) => memoize((clientId) => clientData[clientId].isConnected)
);

export const clientDevicesFilterSelector = createSelector(
  clientDataSelector,
  (clientData) =>
    memoize((clientId) => {
      const clientDetail = clientData[clientId];

      if (clientDetail) {
        return clientDetail.ids.map((id) => clientDetail.data[id]);
      }
      return [];
    })
);

export const clientDevicesByTypeFilterSelector = createSelector(
  clientDataSelector,
  (clientData) =>
    memoize(
      (clientId, platformName, deviceType) => {
        const clientDetail = clientData[clientId];

        if (clientDetail) {
          return clientDetail.ids
            .filter((id) => id.startsWith(`${platformName}/${deviceType}`))
            .map((id) => clientDetail.data[id]);
        }
        return [];
      },
      // https://stackoverflow.com/a/28162116/4642316
      // The default hashFunction just uses the first argument to the memoized function as the key.
      (...args) => JSON.stringify(args)
    )
);

export const currentClientDevicesSelector = createSelector(
  currentClientIdSelector,
  currentPlatformNameSelector,
  currentDeviceTypeSelector,
  clientDevicesByTypeFilterSelector,
  (clientId, platformName, deviceType, clientDevicesByTypeFilter) =>
    clientDevicesByTypeFilter(clientId, platformName, deviceType)
);

const localClientSelector = (state) => state[KEY].localClient;

export const localClientIdSelector = createSelector(
  localClientSelector,
  (localClient) => localClient.clientId
);

export const hubUrlSelector = createSelector(
  localClientSelector,
  (localClient) => {
    if (localClient.hubPort) {
      return `http://localhost:${localClient.hubPort}`;
    }
    return null;
  }
);

export const connectedClientIdsFilterSelector = createSelector(
  clientIdsSelector,
  clientDataSelector,
  localClientSelector,
  (ids, data, localClient) =>
    memoize((clientOnly) => {
      const { clientId: localClientId } = localClient;
      if (clientOnly) {
        return data[localClientId]?.isConnected ? [localClientId] : [];
      }
      const connectedClientIds = ids.filter((id) => data[id].isConnected);
      const mergedClientIds =
        connectedClientIds.indexOf(localClientId) > 0
          ? uniq([localClientId, ...connectedClientIds])
          : connectedClientIds;

      return mergedClientIds;
    })
);

// TODO: Get socket.io-client from Gatsby dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import Cookies from 'universal-cookie';
import {
  call,
  put,
  takeLatest,
  fork,
  take,
  actionChannel,
} from 'redux-saga/effects';
import { signInSuccess, signOutSuccess } from 'redux/Auth/slice';
import { setLocalClientInfo, watchLocalWebSocket } from './slice';

const CONNECTED = 'connected';
const DISCONNECTED = 'disconnected';
const CLIENT_INFO = 'client-info';
const ACCOUNT_SYNC = 'account-sync';

const createLocalWebSocketPromise = () =>
  new Promise((resolve) => {
    const ws = io('http://localhost:3001');

    ws.on('connect', () => {
      console.log('[LOCAL WS][CONNECTED]');
      resolve(ws);
    });

    ws.on('error', (event) => {
      console.error('[LOCAL WS][ERROR] WebSocket error observed:', event);
      // Do not reject to wait until resolved
    });

    ws.on('disconnect', (reason) => {
      console.error('[LOCAL WS][DISCONNECT] Reason:', reason);

      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        ws.connect();
      }
      // else the socket will automatically try to reconnect
    });
  });

const syncAccount = (ws) => {
  const cookies = new Cookies();

  ws.send({
    action: ACCOUNT_SYNC,
    payload: {
      idToken: cookies.get('idToken'),
      refreshToken: cookies.get('refreshToken'),
      username: cookies.get('username'),
    },
  });
};

const requestAfterLocalConnected = (ws) => {
  ws.send({ action: CLIENT_INFO });

  syncAccount(ws);
};

function createLocalSocketChannel(ws) {
  return eventChannel((emit) => {
    ws.on('message', (data) => {
      emit(data);
    });

    ws.on('connect', () => {
      emit({ action: CONNECTED });
    });

    ws.on('disconnect', () => {
      emit({ action: DISCONNECTED });
    });

    return () => {
      // Do nothing
    };
  });
}

function* receiveLocalMessage(wsChannel, ws) {
  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const { action, payload } = yield take(wsChannel);
      switch (action) {
        case CONNECTED: {
          requestAfterLocalConnected(ws);
          break;
        }
        case DISCONNECTED: {
          yield put(setLocalClientInfo({ clientId: null, hubPort: null }));
          break;
        }
        case CLIENT_INFO: {
          yield put(setLocalClientInfo(payload));
          break;
        }
        default:
          break;
      }
    } catch (err) {
      console.log('handleReceiveMessage err', err);
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      wsChannel.close();
    }
  }
}

function* handleSignedInOut(ws) {
  const requestChan = yield actionChannel([signInSuccess, signOutSuccess]);
  while (true) {
    yield take(requestChan);
    syncAccount(ws);
  }
}

function* watchSocketRequest(ws) {
  yield fork(handleSignedInOut, ws);
}

function* connectLocalWebSocket() {
  const socket = yield call(createLocalWebSocketPromise);

  requestAfterLocalConnected(socket);

  const socketChannel = yield call(createLocalSocketChannel, socket);

  yield fork(receiveLocalMessage, socketChannel, socket);

  yield fork(watchSocketRequest, socket);
}

function* watchLocalWebSocketWorker() {
  yield fork(connectLocalWebSocket);
}

export default [takeLatest(watchLocalWebSocket, watchLocalWebSocketWorker)];

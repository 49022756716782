import { get, post, put, del } from '../utils/api';

export async function getProjects() {
  return get('projects');
}

export async function createProject(data) {
  return post('projects', data);
}

export async function getProject(data) {
  return get(`projects/${data.id}`);
}

export async function updateProject(data) {
  return put(`projects/${data.id}`, data);
}

export async function deleteProject(data) {
  return del(`projects/${data.id}`, data);
}

export async function getTestSuites(data) {
  return get(`projects/${data.id}/test-suites`);
}

export async function createTestSuite(data) {
  return post(`projects/${data.id}/test-suites`, data);
}

// TODO: Test Suites end-point
export async function getTestSuite(data) {
  return get(`test-suites/${data.id}`);
}

export async function updateTestSuite(data) {
  return put(`test-suites/${data.id}`, data);
}

export async function deleteTestSuite(data) {
  return del(`test-suites/${data.id}`, data);
}

export async function getTestCases({ id }, filters) {
  return get(`test-suites/${id}/test-cases`, filters);
}

export async function createTestCase(data) {
  return post(`test-suites/${data.id}/test-cases`, data);
}

// TODO: Test Cases end-point

export async function getTestCase(data) {
  return get(`test-cases/${data.id}`);
}

export async function updateTestCase(data) {
  return put(`test-cases/${data.id}`, data);
}

export async function deleteTestCase(data) {
  return del(`test-cases/${data.id}`, data);
}

/* eslint-disable no-await-in-loop */

import { TouchAction } from 'wd';
import { message } from 'antd';
import delay from 'utils/delay';

// const isDevelopment = process.env.NODE_ENV === 'development';

const KEEP_ALIVE_PING_INTERVAL = 20 * 1000; // Currently, timeout of Selenium hub is 30s
// const NO_NEW_COMMAND_LIMIT = isDevelopment ? 30 * 1000 : 10 * 60 * 1000;
// const WAIT_FOR_USER_KEEP_ALIVE = 5 * 60 * 1000;

export default class AppiumMethodHandler {
  constructor(driver) {
    this.driver = driver;
    this.elementCache = {};
    this.elVariableCounter = 0;
    this.elArrayVariableCounter = 0;
    this._lastActiveMoment = +new Date();
    this.variableStorage = {};
  }

  async init(desiredCapabilities) {
    const platformName = desiredCapabilities?.platformName?.toLowerCase();
    this.isIOS = platformName === 'ios';
    this.isAndroid = platformName === 'android';
    return this.driver.init(desiredCapabilities);
  }

  /**
   * Ping server every 30 seconds to prevent `newCommandTimeout` from killing session
   */
  runKeepAliveLoop() {
    this.keepAlive = setInterval(async () => {
      this.driver.sessionCapabilities(); // Pings the Appium server to keep it alive
      // const now = +new Date();

      // If the new command limit has been surpassed, prompt user if they want to keep session going
      // Give them 30 seconds to respond
      // if (now - this._lastActiveMoment > NO_NEW_COMMAND_LIMIT) {
      //   // this.sender.send('appium-prompt-keep-alive');

      //   // After the time limit kill the session (this timeout will be killed if they keep it alive)
      //   this.waitForUserTimeout = setTimeout(() => {
      //     this.close('Session closed due to inactivity');
      //   }, WAIT_FOR_USER_KEEP_ALIVE);
      // }
    }, KEEP_ALIVE_PING_INTERVAL);
  }

  /**
   * Get rid of the intervals to keep the session alive
   */
  killKeepAliveLoop() {
    clearInterval(this.keepAlive);
    if (this.waitForUserTimeout) {
      clearTimeout(this.waitForUserTimeout);
    }
  }

  /**
   * Reset the new command clock and kill the wait for user timeout
   */
  keepSessionAlive() {
    this._lastActiveMoment = +new Date();
    if (this.waitForUserTimeout) {
      clearTimeout(this.waitForUserTimeout);
    }
  }

  async fetchElement(strategy, selector) {
    const element = await this.driver.elementOrNull(strategy, selector);
    if (element === null) {
      return {};
    }
    const id = element.value;

    // TODO: Cache this ID along with its variable name, variable type and strategy/selector
    const cachedEl = {
      el: element,
      variableType: 'string',
      strategy,
      selector,
      id,
    };
    this.elementCache[id] = cachedEl;

    return {
      ...cachedEl,
      strategy,
      selector,
      id,
    };
  }

  async fetchElements(strategy, selector) {
    const els = await this.driver.elements(strategy, selector);
    const variableName = `els${(this.elArrayVariableCounter += 1)}`;
    const variableType = 'array';

    // Cache the elements that we find
    const elements = els.map((el, index) => {
      const res = {
        el,
        variableName,
        variableIndex: index,
        variableType: 'string',
        id: el.value,
        strategy,
        selector,
      };
      this.elementCache[el.value] = res;
      return res;
    });

    return {
      variableName,
      variableType,
      strategy,
      selector,
      elements,
    };
  }

  /**
   * TODO: References
   * TODO: Source code: https://github.com/appium/appium-xcuitest-driver/blob/master/lib/commands/gesture.js#L114
   * TODO: How to run mobile script: https://developers.perfectomobile.com/pages/viewpage.action?pageId=25199704
   * TODO: How to use iOS Predicate:  https://appium.readthedocs.io/en/latest/en/writing-running-appium/ios/ios-predicate/
   * TODO: How to use iOS Predicate: https://appiumpro.com/editions/8
   * @param {*} param0
   */
  async scrollAndFindUntilVisible(search, skipScreenshotAndSource = false) {
    const { driver } = this;
    let { platformName } = await driver.sessionCapabilities();
    platformName = platformName.toLowerCase();
    const { value, variable, criteria, maxTries = 1 } = search;

    // TODO: Get swipe info from options
    const searchValue = variable ? this.variableStorage[variable] : value;
    let element;
    const iOSValue = searchValue;
    let androidValue = searchValue;

    // TODO: Define expression
    let iOSExp;
    let androidExp;
    switch (criteria) {
      case 'contains':
        iOSExp = 'CONTAINS';
        androidExp = 'textContains';
        break;
      case 'beginsWith':
        iOSExp = 'BEGINSWITH';
        androidExp = 'textStartsWith';
        break;
      case 'endsWith': {
        iOSExp = 'ENDSWITH';
        androidExp = 'textMatches';
        // TODO: Insert before special key of Java regex a letter \
        // Reference JAVA: https://stackoverflow.com/a/43153528/4642316
        const replaced = androidValue.replace(
          // eslint-disable-next-line no-useless-escape
          /[\<\(\[\{\\\^\-\=\$\!\|\]\}\)\?\*\+\.\>]/gi,
          '\\$&'
        );
        androidValue = `.*${replaced}$`;
        break;
      }
      case 'equals':
      default:
        iOSExp = '==';
        androidExp = 'text';
        break;
    }
    // TODO: IOS CASE
    if (platformName === 'ios') {
      const scrollElement = await driver.elementByXPath(
        '//XCUIElementTypeScrollView'
      );
      const [scrollLocation, scrollSize] = await Promise.all([
        scrollElement.getLocation(),
        scrollElement.getSize(),
      ]);

      // TODO: Don't create selector directly inside mobile: scroll, cause of literal error
      const selector = `label ${iOSExp} ${JSON.stringify(iOSValue)}`;
      let count = 0;
      while (count <= maxTries) {
        message.info(`Tried time: ${count}`);
        if (count !== 0) {
          message.info('Swipe up');
          try {
            await new TouchAction(this.driver)
              .press({
                x: scrollLocation.x + scrollSize.width / 2,
                y: scrollLocation.y + (scrollSize.height * 4) / 5,
              })
              .wait({ ms: 2000 })
              .moveTo({
                x: scrollLocation.x + scrollSize.width / 2,
                y: scrollLocation.y + scrollSize.height / 5,
              })
              .release()
              .perform();
          } catch (error) {
            console.log("Couldn't swipe up", error);
          }
        }
        count += 1;

        try {
          message.info('Try to find element');
          const response = await driver.execute('mobile: scroll', {
            predicateString: selector,
          });
          if (response?.error) {
            throw response;
          }
          // TODO: Get element value after swipe to there
          const elements = await driver.elements(
            '-ios predicate string',
            selector
          );
          element = elements?.[elements.length - 1];
          break;
        } catch (error) {
          console.log('Scroll and find ios error: ', error);
        }
      }
      // TODO: ANDROID CASE
    } else if (platformName === 'android') {
      // TODO: Search Text Contain
      try {
        message.info('Try to find element');
        const query = `new UiSelector().${androidExp}(${JSON.stringify(
          androidValue
        )})`;
        const scrollAndFind =
          'new UiScrollable(new UiSelector().scrollable(true).instance(0))' +
          `.setMaxSearchSwipes(${maxTries}).setSwipeDeadZonePercentage(0.3)` +
          `.scrollIntoView(${query});`;
        await driver.elementByAndroidUIAutomator(scrollAndFind);
        element = await driver.elementByAndroidUIAutomator(query);
      } catch (error) {
        console.error('scrollAndFindUntilVisible error', error);
        message.info('Sleep 4s');
        await delay(4);
        message.info('Try again');
      }
    }

    // TODO: Give the source/screenshot time to change before taking the screenshot
    let sourceAndScreenshot;
    if (!skipScreenshotAndSource) {
      await delay(500);
      sourceAndScreenshot = await this.#getSourceAndScreenshot();
    }

    if (element) {
      message.success('Found element');
    } else {
      message.error('Could not find element.');
      // throw new Error('Could not find element.');
      return {
        ...sourceAndScreenshot,
        search,
        id: null,
      };
    }

    // If cannot find element, or just scroll to by specified time, won't show interaction modal
    const id = element && element.value;

    // TODO: Cache this ID along with its variable name, variable type and strategy/selector
    const cachedEl = {
      el: element,
      variableType: 'string',
      variableName: `el${(this.elVariableCounter += 1)}`, // TODO: Assign variable name for element
      id,
    };

    this.elementCache[id] = cachedEl;

    return {
      ...cachedEl,
      ...sourceAndScreenshot,
      search,
      id,
    };
  }

  async scrollWithSpecificCoordinates(swipe, skipScreenshotAndSource = false) {
    // TODO: Get swipe info from options
    const { start, end, maxSwipe } = swipe;

    // TODO: Scroll as expected
    for (let i = 0; i < maxSwipe; i += 1) {
      await new TouchAction(this.driver)
        .press({ x: start.x, y: start.y })
        .wait({ ms: 1000 })
        .moveTo({ x: end.x, y: end.y })
        .release()
        .perform();
      await delay(4000);
    }

    // TODO: Give the source/screenshot time to change before taking the screenshot

    let sourceAndScreenshot;
    if (!skipScreenshotAndSource) {
      await delay(500);
      sourceAndScreenshot = await this.#getSourceAndScreenshot();
    }
    // If cannot find element, or just scroll to by specified time, won't show interaction modal
    return {
      ...sourceAndScreenshot,
      swipe,
    };
  }

  async #execute({
    elementId,
    methodName,
    args,
    skipScreenshotAndSource,
    skipExec,
  }) {
    this._lastActiveMoment = +new Date();
    let cachedEl;
    let res = {};
    if (elementId) {
      // TODO: Give the cached element a variable name (el1, el2, el3,...) the first time it's used
      cachedEl = this.elementCache[elementId];
      if (
        cachedEl &&
        !cachedEl.variableName &&
        cachedEl.variableType === 'string'
      ) {
        cachedEl.variableName = `el${(this.elVariableCounter += 1)}`;
      }
      // res = await cachedEl.el[methodName].apply(cachedEl.el, args);
      if (!cachedEl.el) {
        throw new Error('Could not find element in order to do action');
      }

      if (!skipExec) {
        if (methodName === 'sendKeys') {
          const [sendKeysValue, sendKeysVariable, sendKeysAction] = args;
          const value = sendKeysVariable
            ? this.variableStorage[sendKeysVariable]
            : sendKeysValue;
          res = await cachedEl.el.sendKeys(
            value?.replace(/\$ENTER#/g, '\n') // TODO: Replace specified key for Enter or Backspace
          );

          if (sendKeysAction) {
            let { platformName } = await this.driver.sessionCapabilities();
            platformName = platformName.toLowerCase();

            if (platformName === 'android') {
              await cachedEl.el.click();
              await this.driver.pressKeycode(66); // KEYCODE_ENTER
            } else if (platformName === 'ios') {
              await cachedEl.el.sendKeys('\n');
            }
          }
        } else if (methodName === 'text') {
          res = await cachedEl.el.text();
          const [assertValue, assertVariable, assertType] = args;
          const actual = res;
          const expected = assertVariable
            ? this.variableStorage[assertVariable]
            : assertValue;

          // TODO: switch strategy
          switch (assertType) {
            case 'contain':
              if (!actual.includes(expected)) {
                throw new Error(
                  'Actual value does not contain expected value.'
                );
              }
              break;
            case 'notContain':
              if (actual.includes(expected)) {
                throw new Error('Actual value contains expected value.');
              }
              break;
            case 'notEqual':
              if (actual === expected) {
                throw new Error('Actual value is equal to expected value');
              }
              break;
            default:
              if (actual !== expected) {
                throw new Error('Actual value is not equal to expected value');
              }
              break;
          }
        } else if (methodName === 'longPress') {
          const [seconds] = args;
          res = new TouchAction(this.driver)
            .press({ el: cachedEl.el })
            .wait(seconds * 1000)
            .release()
            .perform();
        } else {
          // TODO: DO ACTION ON ELEMENT (MOST IMPORTANT)
          res = await cachedEl.el[methodName](...args); // TODO: Get element and call method
        }
      }
    } else if (methodName === 'tap') {
      // TODO: Specially handle the tap method
      res = await new TouchAction(this.driver)
        .tap({ x: args[0], y: args[1] })
        .perform();
    } else if (methodName === 'swipe') {
      // TODO: Specially handle swipe method
      const [startX, startY, endX, endY] = args;
      res = await new TouchAction(this.driver)
        .press({ x: startX, y: startY })
        .wait({ ms: 3000 })
        .moveTo({ x: endX, y: endY })
        .release()
        .perform();
    } else if (methodName === 'pressPhysicalButton') {
      const [buttonName, times = 1] = args;

      let iOSBtn;
      let androidKeyEvent;
      switch (buttonName) {
        case 'home':
          iOSBtn = 'home';
          androidKeyEvent = 3; // KEYCODE_HOME
          break;
        case 'volumeUp':
          iOSBtn = 'volumeup';
          androidKeyEvent = 24; // KEYCODE_VOLUME_UP
          break;
        case 'volumeDown':
          iOSBtn = 'volumedown';
          androidKeyEvent = 25; // KEYCODE_VOLUME_DOWN
          break;
        default:
          throw new Error(`Unknown button name: ${buttonName}`);
      }

      if (this.isIOS) {
        for (let i = 0; i < times; i += 1) {
          await this.driver.execute('mobile: pressButton', { name: iOSBtn });
        }
      } else if (this.isAndroid) {
        for (let i = 0; i < times; i += 1) {
          await this.driver.pressKeycode(androidKeyEvent);
        }
      }
    } else if (methodName === 'switchApp') {
      if (this.isIOS) {
        const [, bundleId] = args;
        await this.driver.execute('mobile: activateApp', { bundleId });
      } else if (this.isAndroid) {
        const [, appPackage, appActivity] = args;
        await this.driver.startActivity({
          appPackage,
          appActivity,
        });
      }
    } else if (methodName !== 'source' && methodName !== 'screenshot') {
      if (methodName === 'refresh') {
        const contexts = await this.driver.contexts();
        await this.driver.context([...contexts].pop());
      }
      res = await this.driver[methodName](...args);
      if (methodName === 'refresh') {
        await this.driver.context('NATIVE_APP');
      }
    } else {
      res = null;
    }

    let sourceAndScreenshot;
    if (!skipScreenshotAndSource) {
      // TODO: Give the source/screenshot time to change before taking the screenshot
      // Increase from 500ms to 1500ms, because sometimes the source/screenshot could not fetch data (unknown reason)
      await delay(2000);
      sourceAndScreenshot = await this.#getSourceAndScreenshot();
    }

    return {
      ...sourceAndScreenshot,
      ...cachedEl,
      res,
    };
  }

  async executeElementCommand(
    elementId,
    methodName,
    args = [],
    skipScreenshotAndSource = false,
    skipExec = false
  ) {
    const exec = await this.#execute({
      elementId,
      methodName,
      args,
      skipScreenshotAndSource,
      skipExec,
    });
    return exec;
  }

  async executeMethod(methodName, args = [], skipScreenshotAndSource = false) {
    const exec = await this.#execute({
      methodName,
      args,
      skipScreenshotAndSource,
    });
    return exec;
  }

  async #getSourceAndScreenshot() {
    const result = {};

    const [sourceRes, screenshotRes, windowSizeRes] = await Promise.allSettled([
      this.driver.source(),
      this.driver.takeScreenshot(),
      this.driver.getWindowSize(),
    ]);

    if (sourceRes.status === 'fulfilled') {
      if (sourceRes.value.error) {
        result.sourceError = sourceRes.value;
      } else {
        result.source = sourceRes.value;
      }
    } else {
      if (sourceRes.reason.status === 6) {
        throw sourceRes.reason;
      }
      sourceRes.sourceError = sourceRes.reason;
    }

    if (screenshotRes.status === 'fulfilled') {
      if (screenshotRes.value.error) {
        result.screenshotError = screenshotRes.value;
      } else {
        result.screenshot = screenshotRes.value;
      }
    } else {
      if (screenshotRes.reason.status === 6) {
        throw screenshotRes.reason;
      }
      screenshotRes.screenshotError = screenshotRes.reason;
    }

    if (windowSizeRes.status === 'fulfilled') {
      if (windowSizeRes.value.error) {
        result.windowSizeError = windowSizeRes.value;
      } else {
        result.windowSize = windowSizeRes.value;
      }
    } else {
      if (windowSizeRes.reason.status === 6) {
        throw windowSizeRes.reason;
      }
      windowSizeRes.windowSizeError = windowSizeRes.reason;
    }

    return result;
  }

  restart() {
    // Clear the variable names and start over (el1, el2, els1, els2, etc...)
    for (const elCache of Object.values(this.elementCache)) {
      delete elCache.variableName;
    }

    // Restart the variable counter
    this.elVariableCounter = 0;
    this.elArrayVariableCounter = 0;
  }

  async close() {
    this.killKeepAliveLoop();
    if (!this.driver._isAttachedSession) {
      try {
        await this.driver.quit();
      } catch (ign) {
        console.error('Catch ignore', ign);
        // TODO: Nothing
      }
    }
  }
}

import { notification } from 'antd';

export function showErrorAppium(e, methodName, secs = 5) {
  let errMessage;
  if (e['jsonwire-error'] && e['jsonwire-error'].status === 7) {
    errMessage = `Failed to find element for '${methodName}'. Refresh page and try again.`;
  } else if (e.data) {
    try {
      e.data = JSON.parse(e.data);
    } catch (ign) {
      console.error('showErrorAppium ign', ign);
    }
    if (e.data.value && e.data.value.message) {
      errMessage = e.data.value.message;
    } else {
      errMessage = e.data;
    }
  } else if (e.message) {
    errMessage = e.message;
  } else if (e.code) {
    errMessage = e.code;
  } else {
    errMessage = 'Could not start session';
  }
  if (errMessage === 'ECONNREFUSED') {
    errMessage = "Could not connect to server. Are you sure it's running?";
  }

  notification.error({
    message: 'Error',
    description: errMessage,
    duration: secs || 10,
  });
}

import { memoize } from 'lodash';
import { createSelector } from 'reselect';

export const dataSelectorFactory = (key) => (state) => state[key]?.data;
export const currentIdSelectorFactory = (key) => (state) =>
  state[key]?.currentId;
export const idsSelectorFactory = (key) => (state) => state[key]?.ids;
export const containerSelectorFactory = (key) => (state) =>
  state[key]?.containers;
export const loadingSelectorFactory = (key) => (state) => state[key]?.loading;
export const loadsSelectorFactory = (key) => (state) => state[key]?.loads;

/**
 * Get item array by container id (ex: ProjectID get list TestSuites)
 * @param {*} key
 * @param {*} containerId
 * @returns returns list of items based on container id
 */
export const itemsByContainerIdSelectorFactory = (key) =>
  createSelector(
    dataSelectorFactory(key),
    containerSelectorFactory(key),
    /* eslint-disable prettier/prettier */
    (data, containers) =>
      memoize((containerId) => containers?.[containerId]
        ? containers[containerId]
          .map((id) => data[id])
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        : [])
    /* eslint-enable prettier/prettier */
  );

// TODO: Get all list data
export const listSelectorFactory = (key) =>
  createSelector(
    dataSelectorFactory(key),
    idsSelectorFactory(key),
    /* eslint-disable prettier/prettier */
    (data, ids) =>
      ids
        ? ids
          .map((id) => data?.[id])
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        : []
    /* eslint-enable prettier/prettier */
  );

// TODO: Get current data
export const currentDataSelectorFactory = (key) =>
  createSelector(
    dataSelectorFactory(key),
    currentIdSelectorFactory(key),
    (data, currentId) => {
      if (data && currentId) {
        return data?.[currentId] || {};
      }
      return {};
    }
  );

/**
 * Get item array by container id (ex: ProjectID get list TestSuites)
 * @param key: containerID
 * @returns returns list of items based on container id
 */
export const loadingByContainerIdSelectorFactory = (key) =>
  createSelector(loadsSelectorFactory(key), (loads) =>
    memoize((containerId) => loads?.[containerId])
  );

/**
 * Get data by ID
 * @param {*} id
 */
export const dataByIdSelectorFactory = (key) =>
  createSelector(dataSelectorFactory(key), (data) =>
    memoize((id) => data?.[id] || {})
  );

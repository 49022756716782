import { get, post, put, del } from '../utils/api';

export async function getFiles({ id, ...data }) {
  return get(`projects/${id}/files`, data);
}

export async function createFile(data) {
  return post(`projects/${data.id}/files`, data);
}

export async function getFile(data) {
  return get(`files/${data.id}`);
}

export async function updateFile(data) {
  return put(`files/${data.id}`, data);
}

export async function deleteFile(data) {
  return del(`files/${data.id}`, data);
}

export async function getFileCredentials(data) {
  return get('files/credentials', data);
}

import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';
import { quitSessionDone } from 'redux/Inspector/slice';

const initialState = {
  current: {},
  data: {},
  ids: [],
  loading: false,
  containers: {},
  loads: {},
};

const slice = createSlice({
  name: 'testSuites',
  initialState,
  reducers: {
    // TODO: GET ALL
    getTestSuites(state, { payload: { id } }) {
      state.loading = true;
      state.loads[id] = true;
    },
    getTestSuitesSuccess(state, { payload: { containerId, ids, data } }) {
      state.ids = union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.containers[containerId] = ids;
      state.loads[containerId] = false;
      state.loading = false;
    },
    getTestSuitesFailure(state, { payload: { containerId } }) {
      state.loading = false;
      state.loads[containerId] = false;
    },
    // TODO: DETAIL
    getTestSuiteDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getTestSuiteDetailSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    getTestSuiteDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE
    createTestSuite(state) {
      state.loading = true;
    },
    createTestSuiteSuccess(state, { payload: { containerId, data } }) {
      state.data[data.id] = data;
      state.ids = [...state.ids, data.id];
      state.containers = {
        ...state.containers,
        [containerId]: [data.id, ...state.containers[containerId]],
      };
      state.loading = false;
    },
    createTestSuiteFailure(state) {
      state.loading = false;
    },
    updateTestSuite(state) {
      state.loading = true;
    },
    updateTestSuiteSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    updateTestSuiteFailure(state) {
      state.loading = false;
    },
    // TODO: DELETE
    deleteTestSuite(state) {
      state.loading = true;
    },
    deleteTestSuiteSuccess(state, { payload }) {
      state.currentId = null;
      state.containers = {};
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteTestSuiteFailure(state) {
      state.loading = false;
    },
    removeCurrentTestSuite(state) {
      state.currentId = null;
    },
  },
  extraReducers: {
    [quitSessionDone]: () => initialState,
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getTestSuites,
  getTestSuitesSuccess,
  getTestSuitesFailure,
  getTestSuiteDetail,
  getTestSuiteDetailSuccess,
  getTestSuiteDetailFailure,
  createTestSuite,
  createTestSuiteSuccess,
  createTestSuiteFailure,
  updateTestSuite,
  updateTestSuiteSuccess,
  updateTestSuiteFailure,
  deleteTestSuite,
  deleteTestSuiteSuccess,
  deleteTestSuiteFailure,
  removeCurrentTestSuite,
} = actions;

export default reducer;

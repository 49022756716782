import { isNode } from 'react-flow-renderer';
import { createSelector } from 'reselect';
import {
  convertPureElements,
  getVariableComponentIdPair,
} from 'utils/action-flow';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';
import { currentFileSelector } from 'redux/Files/selector';
import { pickBy } from 'lodash';
import { selectedDeviceSelector } from 'redux/ClientDevices/selectors';

const KEY = 'inspector';

export const frameworkNameSelector = (state) => state[KEY].frameworkName;
export const recordingSelector = (state) => state[KEY].recording;
export const playingSelector = (state) => state[KEY].playing;
export const screenshotInteractionModeSelector = (state) =>
  state[KEY].screenshotInteractionMode;
export const swipeStartSelector = (state) => state[KEY].swipe.start;
export const swipeEndSelector = (state) => state[KEY].swipe.end;
const allowedCloseSelector = (state) => state[KEY].allowedClose;
export const selectedElementPathSelector = (state) =>
  state[KEY].selectedElementPath;
export const selectedElementSelector = (state) =>
  state[KEY].selectedElement || {};
export const hoveredElementSelector = (state) => state[KEY].hoveredElement;
export const selectedElementIdSelector = (state) =>
  state[KEY].selectedElementId;
export const elementInteractionsNotAvailableSelector = (state) =>
  state[KEY].elementInteractionsNotAvailable;
// TODO: Scroll and  find
export const scrollAndFindDrawerVisibleSelector = (state) =>
  state[KEY].scrollAndFindDrawerVisible;

// TODO: Select point for swipe
export const swipeByTimesDrawerVisibleSelector = (state) =>
  state[KEY].swipeByTimesDrawerVisible;

export const expandedPathsSelector = (state) => state[KEY].expandedPaths;

// TODO: Assert drawer
export const assertDrawerVisibleSelector = (state) =>
  state[KEY].assertDrawerVisible;

// TODO: Send keys drawer
export const sendKeysDrawerVisibleSelector = (state) =>
  state[KEY].sendKeysDrawerVisible;

// TODO: Client devices drawer
export const clientDevicesDrawerVisibleSelector = (state) =>
  state[KEY].clientDevicesDrawerVisible;

// TODO: Element detail drawer
export const elementDetailDrawerVisibleSelector = (state) =>
  state[KEY].elementDetailDrawerVisible;

export const errorRowSelector = (state) => state[KEY].errorRow;
export const currentRowSelector = (state) => state[KEY].currentRow;

// TODO: Searched elements drawer
export const searchingSelector = (state) => state[KEY].searching;
export const searchedElementsDrawerVisibleSelector = (state) =>
  state[KEY].searchedElementsDrawerVisible;

export const searchedElementsSelector = (state) => state[KEY].searchedElements;
export const moreActionsDrawerVisibleSelector = (state) =>
  state[KEY].moreActionsDrawerVisible;
export const searchedElementBoundsSelector = (state) =>
  state[KEY].searchedElementBounds;
export const searchedElementIdSelector = (state) =>
  state[KEY].searchedElementId;

// TODO: TAP
export const tapPointSelector = (state) => state[KEY].tapPoint;
export const selectTapPointFromSelector = (state) =>
  state[KEY].selectTapPointFrom;

// TODO: Swipe
export const selectSwipePointsFromSelector = (state) =>
  state[KEY].selectSwipePointsFrom;

// TODO: Appium
export const variableStorageSelector = (state) => state[KEY].variableStorage;
export const savedSelector = (state) => state[KEY].saved;
export const allowedCloseInspectorSelector = createSelector(
  allowedCloseSelector,
  savedSelector,
  (allowedClose, saved) => allowedClose || saved
);
export const sourceXMLSelector = (state) => state[KEY].sourceXML;
export const sourceSelector = (state) => state[KEY].source;
export const sourceErrorSelector = (state) => state[KEY].sourceError;
export const sessionLoadingSelector = (state) => state[KEY].sessionLoading;
export const windowSizeSelector = (state) => state[KEY].windowSize;
export const methodCallInProgressSelector = (state) =>
  state[KEY].methodCallInProgress;
export const screenshotSelector = (state) => state[KEY].screenshot;

const appUnderTestInfoSelector = createSelector(
  currentTestSuiteSelector,
  currentFileSelector,
  (testSuite, file) => {
    const info = { appName: 'App Under Test' };
    const isIOS = testSuite?.platformName?.toLowerCase() === 'ios';
    const isAndroid = testSuite?.platformName?.toLowerCase() === 'android';

    if (isIOS) {
      if (testSuite.fileId && file) {
        info.bundleId = file.bundleId;
      } else if (testSuite.bundleId) {
        info.bundleId = testSuite.bundleId;
      }
    } else if (isAndroid) {
      if (testSuite.fileId && file) {
        info.appPackage = file.appPackage;
        info.appActivity = file.appActivity;
      } else if (testSuite.appPackage && testSuite.appActivity) {
        info.appPackage = testSuite.appPackage;
        info.appActivity = testSuite.appActivity;
      }
    }
    return info;
  }
);

export const appsSelector = (state) => state[KEY].apps;

export const mergedAppsSelector = createSelector(
  appsSelector,
  appUnderTestInfoSelector,
  (apps, appUnderTestInfo) => ({
    [appUnderTestInfo.appName]: appUnderTestInfo,
    ...apps,
  })
);

// ==========================================
// TODO: ACTION FLOWS
// ==========================================
export const componentsDataSelector = (state) => state[KEY].componentsData;
export const pureElementsDataSelector = (state) => state[KEY].pureElementsData;
export const pureElementsSelector = createSelector(
  pureElementsDataSelector,
  (pureElementsData) => Object.values(pureElementsData)
);
export const recordedActionsSelector = createSelector(
  currentTestSuiteSelector,
  pureElementsDataSelector,
  componentsDataSelector,
  ({ platformName }, pureElementsData, componentsData) => {
    const pureElements = Object.values(pureElementsData);
    return convertPureElements(platformName, pureElements, componentsData);
  }
);
export const actionsSelector = createSelector(
  pureElementsDataSelector,
  (pureElementsData) =>
    Object.values(pureElementsData).filter((action) => isNode(action))
);
export const zoomSelector = (state) => state[KEY].zoom;
export const variableComponentIdPairSelector = createSelector(
  componentsDataSelector,
  (componentsData) => getVariableComponentIdPair(componentsData)
);
export const usedVariablesDataSelector = createSelector(
  variableStorageSelector,
  variableComponentIdPairSelector,
  (variableStorage, variableComponentIdPair) => ({
    ...variableStorage,
    ...variableComponentIdPair,
  })
);
export const usedVariablesSelector = createSelector(
  usedVariablesDataSelector,
  (usedVariablesData) => Object.keys(usedVariablesData)
);

export const loadingScreenshotSelector = createSelector(
  methodCallInProgressSelector,
  screenshotSelector,
  playingSelector,
  (methodCallInProgress, screenshot, playing) =>
    methodCallInProgress || !screenshot || playing
);

export const adjustVolumeDrawerVisibleSelector = (state) =>
  state[KEY].adjustVolumeDrawerVisible;

export const switchAppDrawerVisibleSelector = (state) =>
  state[KEY].switchAppDrawerVisible;

export const newTargetAppDrawerVisibleSelector = (state) =>
  state[KEY].newTargetAppDrawerVisible;

export const editTargetAppDrawerVisibleSelector = (state) =>
  state[KEY].editTargetAppDrawerVisible;

export const selectedTargetAppSelector = (state) =>
  state[KEY].selectedTargetApp;

export const capabilitiesSelector = createSelector(
  currentTestSuiteSelector,
  currentFileSelector,
  selectedDeviceSelector,
  (testSuite, file, selectedDevice) => {
    const {
      id: testSuiteId,
      projectId,
      bundleId,
      fileId,
      browserName,
      appPackage,
      appActivity,
      deviceType,
      teamId,
      platformName,
    } = testSuite;

    const { deviceName, platformVersion, udid } = selectedDevice || {};

    let desiredCapabilities = {
      platformName,
      deviceName: udid ? 'Any' : deviceName,
      platformVersion,
      udid,
    };

    // Physical devices
    if (platformName === 'ios' && deviceType === 'device') {
      desiredCapabilities.xcodeOrgId = teamId;
      desiredCapabilities.xcodeSigningId = 'iPhone Developer';
      desiredCapabilities.updatedWDABundleId =
        'biz.dockyard.moppium.webdriveragent';
      desiredCapabilities.allowProvisioningDeviceRegistration = true;
    }

    if (fileId) {
      const { type: fileExt, apiKey } = file;
      if (platformName === 'android') {
        desiredCapabilities.fullReset = true;
      }
      desiredCapabilities.app = `${process.env.GATSBY_API_URL}files/url/api-key?apiKey=${apiKey}&projectId=${projectId}&testSuiteId=${testSuiteId}&fileId=${fileId}&fileExt=${fileExt}`;
    } else if (bundleId) {
      desiredCapabilities.bundleId = bundleId;
    } else if (appPackage && appActivity) {
      desiredCapabilities.appPackage = appPackage;
      desiredCapabilities.appActivity = appActivity;
      desiredCapabilities.noReset = false;
    } else if (browserName && platformName === 'ios') {
      desiredCapabilities.browserName = 'Safari';
    } else if (browserName && platformName === 'android') {
      desiredCapabilities.browserName = 'Browser';
    }

    desiredCapabilities = pickBy(desiredCapabilities);

    return desiredCapabilities;
  }
);

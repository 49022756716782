import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Text } = Typography;

function DeviceNameCell({ name }) {
  return <Text strong>{name}</Text>;
}

DeviceNameCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DeviceNameCell;

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Button, Tabs, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import DeviceNameCell from 'components/ClientDevices/DeviceNameCell';
import DeviceTypeCell from 'components/ClientDevices/DeviceTypeCell';
import usePlatform from 'hooks/usePlatform';
import downloadBrowser from 'utils/download-browser';
import { version } from '../../../package.json';

export const STATUS_INITIAL = 'initial';
export const STATUS_STARTING = 'starting';
export const STATUS_RUNNING = 'running';
export const STATUS_STOPPED = 'stopped';
export const STATUS_STOPPING = 'stopping';

const { Title, Paragraph, Text, Link } = Typography;
const { TabPane } = Tabs;

function ClientDevices({
  rowSelection,
  size,
  style,
  isMini,
  clientDevices,
  onChangeTab,
  clientIds,
  localClientId,
}) {
  const { t } = useTranslation();
  const { isMac } = usePlatform();

  const columns = [
    {
      title: t('deviceName'),
      dataIndex: 'deviceName',
      key: 'deviceName',
      // eslint-disable-next-line react/display-name
      render: (name) => <DeviceNameCell name={name} />,
    },
    {
      title: t('deviceType'),
      dataIndex: 'deviceType',
      key: 'deviceType',
      // eslint-disable-next-line react/display-name
      render: (deviceType) => <DeviceTypeCell label={deviceType} />,
    },
    {
      title: t('version'),
      dataIndex: 'platformVersion',
      key: 'platformVersion',
    },
    {
      title: t('status'),
      dataIndex: 'serverStatus',
      key: 'serverStatus',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        let color;
        let status = text;
        switch (text) {
          case STATUS_INITIAL:
            color = 'cyan';
            break;
          case STATUS_RUNNING:
            color = 'green';
            status = 'connecting';
            break;
          case STATUS_STARTING:
            color = 'purple';
            break;
          case STATUS_STOPPING:
            color = 'volcano';
            break;
          case STATUS_STOPPED:
            color = 'red';
            break;
          default:
            color = 'green';
            break;
        }
        return <DeviceTypeCell label={status} color={color} />;
      },
    },
  ];

  const handleOpenClientApp = () => {
    window.location.replace('moppium-client://');
  };

  const handleDownloadClient = () => {
    downloadBrowser(
      `https://${process.env.GATSBY_ASSETS_BUCKET}.s3-ap-northeast-1.amazonaws.com/public/releases/Moppium+Client-${version}.dmg`
    );
  };

  return (
    <div style={{ marginTop: 5, width: isMini ? 500 : 'unset', ...style }}>
      {!clientIds.length && (
        <>
          <Title level={4}>{t('clientForMobileDevicesManagement')}</Title>
          <Paragraph>{t('clickButtonToOpenClient')}</Paragraph>
        </>
      )}
      <Button onClick={handleOpenClientApp} type="primary">
        {t('openClientApp')}
      </Button>
      <Tabs onChange={onChangeTab} defaultActiveKey={localClientId}>
        {clientIds?.map((id) => (
          <TabPane
            tab={
              <>
                {id}
                {id === localClientId && (
                  <Tag color="volcano" style={{ marginLeft: 10 }}>
                    {t('localClient')}
                  </Tag>
                )}
              </>
            }
            key={id}
          >
            <Table
              size={size}
              columns={columns}
              dataSource={clientDevices}
              pagination={{ pageSize: 5 }}
              rowSelection={rowSelection}
            />
          </TabPane>
        ))}
      </Tabs>
      <br />
      {(isMini || !clientIds.length) && (
        <>
          <Button
            onClick={handleDownloadClient}
            type="primary"
            disabled={!isMac}
          >
            {t('download')}
          </Button>{' '}
          {isMac && (
            <Text type="secondary">
              {t('version')} {version}
            </Text>
          )}
          <br />
          <Link href="/dashboard/download" target="_blank">
            {t('openDownloadPage')}
          </Link>
        </>
      )}
    </div>
  );
}

ClientDevices.propTypes = {
  rowSelection: PropTypes.object,
  size: PropTypes.string,
  style: PropTypes.object,
  isMini: PropTypes.bool,
  clientDevices: PropTypes.array,
  onChangeTab: PropTypes.func,
  clientIds: PropTypes.array,
  localClientId: PropTypes.string,
};

ClientDevices.defaultProps = {
  size: 'middle',
  clientIds: [],
};

export default React.memo(ClientDevices, isEqual);

import { createSlice } from '@reduxjs/toolkit';
import {
  getConnectedDevices,
  getConnectedDevice,
  disconnectedDevice,
  setClientStatus,
} from 'redux/WebSocket/slice';
import { setLocalClientInfo } from 'redux/WebSocketLocal/slice';
import { convertGetAllData } from 'redux/utils/dataProvider';
import uniq from 'lodash/uniq';
import { STATUS_STOPPED } from 'components/ClientDevices';
import { getTestSuiteDetailSuccess } from 'redux/TestSuites/slice';

export const IOS = 'ios';
export const ANDROID = 'android';
export const DEVICE = 'device';
export const SIMULATOR = 'simulator';
export const DEVICE_AND_EMULATOR = 'deviceAndEmulator';
export const IOS_DEVICE = `${IOS}/${DEVICE}`;
export const IOS_SIMULATOR = `${IOS}/${SIMULATOR}`;
export const ANDROID_DEVICE_AND_EMULATOR = `${ANDROID}/${DEVICE_AND_EMULATOR}`;

const slice = createSlice({
  name: 'client-devices',
  initialState: {
    currentPlatformName: null,
    currentDeviceType: null,
    currentClientId: null,
    selectedDevice: null,
    clientIds: [],
    clientData: {},
    localClient: {
      clientId: null,
      hubPort: null,
    },
  },
  reducers: {
    setSelectedDevice(state, { payload }) {
      state.selectedDevice = payload;
    },
  },
  extraReducers: {
    [setLocalClientInfo]: (state, { payload: { clientId, hubPort } }) => {
      state.localClient.clientId = clientId;
      state.localClient.hubPort = hubPort;
    },
    [setClientStatus]: (state, { payload: { clientId, isConnected } }) => {
      const detail = state.clientData?.[clientId];
      if (isConnected) {
        state.clientData[clientId] = {
          isConnected: true,
          ids: detail?.ids ? detail.ids : [],
          data: { ...detail?.data },
        };
        state.clientIds = uniq([...state.clientIds, clientId]);
      } else {
        delete state.clientData[clientId];
        state.clientIds = state.clientIds.filter((id) => id !== clientId);
      }
    },
    [getConnectedDevices]: (state, { payload: { clientId, devices } }) => {
      const { ids, data } = convertGetAllData(devices, 'key');
      state.clientData[clientId] = {
        isConnected: true,
        ids,
        data,
      };
    },
    [getConnectedDevice]: (state, { payload: { clientId, device } }) => {
      const { key } = device;
      const detail = state.clientData?.[clientId];
      state.clientData[clientId] = {
        isConnected: true,
        ids: detail?.ids ? uniq([...detail?.ids, key]) : [key],
        data: { ...detail.data, [key]: device },
      };
    },
    [disconnectedDevice]: (state, { payload: { clientId, key } }) => {
      if (state.clientData?.[clientId]?.data?.[key]?.serverStatus) {
        state.clientData[clientId].data[key].serverStatus = STATUS_STOPPED;
      }
    },
    [getTestSuiteDetailSuccess]: (
      state,
      { payload: { platformName, deviceType, clientId } }
    ) => {
      state.currentPlatformName = platformName;
      state.currentDeviceType = deviceType;
      state.currentClientId = clientId;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const { setSelectedDevice } = actions;

export default reducer;

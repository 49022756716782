import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ws',
  initialState: {},
  reducers: {
    watchWebSocket() {
      // Dispatch to saga
    },
    connect() {
      // Dispatch to saga
    },
    connectSuccess() {
      // Used in saga
    },
    disconnect() {
      // Dispatch to saga
    },
    getConnectedDevice() {
      // Used in ClientDevice slice
    },
    disconnectedDevice() {
      // Used in ClientDevice slice
    },
    getConnectedDevices() {
      // Used in ClientDevice slice
    },
    setClientStatus() {
      // Used in other reducer
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  watchWebSocket,
  connect,
  disconnect,
  getConnectedDevice,
  disconnectedDevice,
  getConnectedDevices,
  connectSuccess,
  setClientStatus,
} = actions;

export default reducer;

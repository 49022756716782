import { takeLatest, call, put } from 'redux-saga/effects';
import * as ResultActions from './slice';
import * as ResultAPIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

export function* getDeviceResultsWorker({ payload: { jobId } }) {
  try {
    const response = yield call(apiCaller, ResultAPIs.getDeviceResults, {
      jobId,
    });

    const { ids, data } = convertGetAllData(response.data);
    yield put(
      ResultActions.getDeviceResultsSuccess({
        containerId: jobId,
        ids,
        data,
      })
    );
  } catch (err) {
    yield put(ResultActions.getDeviceResultsFailure(err));
    showError(err);
  }
}

export function* getDeviceResultWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, ResultAPIs.getDeviceResult, payload);
    yield put(ResultActions.getDeviceResultDetailSuccess(data));
  } catch (err) {
    yield put(ResultActions.getDeviceResultDetailFailure(err));
    showError(err);
  }
}

export function* deleteDeviceResultWorker({ payload }) {
  try {
    const { data } = yield call(
      apiCaller,
      ResultAPIs.deleteDeviceResult,
      payload
    );
    yield put(ResultActions.deleteDeviceResultSuccess(data));
  } catch (err) {
    yield put(ResultActions.deleteDeviceResultFailure(err));
    showError(err);
  }
}

export default [
  takeLatest(ResultActions.getDeviceResults, getDeviceResultsWorker),
  takeLatest(ResultActions.getDeviceResultDetail, getDeviceResultWorker),
  takeLatest(ResultActions.deleteDeviceResult, deleteDeviceResultWorker),
];

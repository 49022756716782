import { promiseChainRemote } from 'wd';
import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import { message, notification } from 'antd';
import { matchRouter } from 'redux/utils/router';
import {
  getTestCaseDetailSuccess,
  updateTestCase,
  updateTestCaseFailure,
  updateTestCaseSuccess,
} from 'redux/TestCases/slice';
import {
  sourceXMLSelector,
  sourceSelector,
  selectedElementPathSelector,
  expandedPathsSelector,
  selectedElementIdSelector,
  componentsDataSelector,
  variableStorageSelector,
  recordingSelector,
  playingSelector,
  pureElementsDataSelector,
  pureElementsSelector,
  usedVariablesSelector,
  variableComponentIdPairSelector,
  appsSelector,
  capabilitiesSelector,
} from 'redux/Inspector/selector';
import delay from 'utils/delay';

import debounce from 'lodash/debounce';
import toPairs from 'lodash/toPairs';
import { getLocators } from 'components/Inspector/shared';
import {
  convertPureElements,
  convertRecordedActions,
  parseRecordedAction,
  removeCustomElements,
  addCustomEdge,
  updateCustomEdge,
  getComponent,
} from 'utils/action-flow';
import { keyBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { xmlToJSON } from 'utils/util';
import { showErrorAppium } from 'redux/utils/show-error-appium';
import { hubUrlSelector } from 'redux/ClientDevices/selectors';
import AppiumMethodHandler from 'lib/AppiumMethodHandler';
import { getIncomers } from 'react-flow-renderer';
import isEqual from 'react-fast-compare';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';

/**
 * Look up an element in the source with the provided path
 */
function findElementByPath(path, source) {
  let selectedElement = source;
  for (const index of path.split('.')) {
    selectedElement = selectedElement.children[index];
  }
  return { ...selectedElement };
}

const initialSwipe = {
  start: { x: null, y: null },
  end: { x: null, y: null },
};

const initialTapPoint = { x: null, y: null };

const initialPureElement = {
  start: {
    id: 'start',
    type: 'StartNode',
    position: { x: 0, y: 0 },
    selectable: false,
  },
  'e-start-end': {
    id: `e-start-end`,
    source: 'start',
    target: 'end',
    arrowHeadType: 'arrowclosed',
  },
  end: {
    id: 'end',
    type: 'EndNode',
    position: { x: 0, y: 100 },
    selectable: false,
  },
};

const initialState = {
  screenshotInteractionMode: 'select',
  recording: true,
  allowedClose: false,
  loading: false,
  swipe: initialSwipe,
  playing: false,
  errorRow: -1,
  currentRow: -1,
  gettingCoordinates: false,
  selectedElementId: null,
  expandedPaths: [],
  searchedElementBounds: {},
  // TODO: Specified for scroll and find drawer
  scrollAndFindDrawerVisible: false,

  // TODO: Specified for send keys drawer
  sendKeysDrawerVisible: false,

  // TODO: Specified for more actions drawer
  moreActionsDrawerVisible: false,

  // TODO: Specified for assert drawer
  assertDrawerVisible: false,

  // TODO: Specified for client
  clientDevicesDrawerVisible: true,

  // TODO: Specified for searched elements
  searchedElementsDrawerVisible: false,
  searchedElements: [],

  // TODO: Specified for swipe by times drawer
  swipeByTimesDrawerVisible: false,

  // TODO: Element detail
  elementDetailDrawerVisible: false,

  // TODO: Tap
  tapPoint: initialTapPoint,

  // TODO: Appium
  variableStorage: {},
  capabilities: {},
  sessionLoading: false,
  apps: {},

  // TODO: Volume
  adjustVolumeDrawerVisible: false,

  // TODO: Switch app
  switchAppDrawerVisible: false,
  newTargetAppDrawerVisible: false,
  editTargetAppDrawerVisible: false,
  selectedTargetApp: null,

  // =====================================
  // TODO: ACTION FLOW
  // =====================================
  zoom: 1,
  saved: true,
  pureElementsData: initialPureElement,
  componentsData: {},
};

const slice = createSlice({
  name: 'inspector',
  initialState,
  reducers: {
    startRecording(state) {
      state.recording = true;
    },
    pauseRecording(state) {
      state.recording = false;
    },
    clearSwipeAction(state) {
      state.swipe = initialSwipe;
    },
    selectScreenshotInteractionMode(state, { payload: { mode } }) {
      state.screenshotInteractionMode = mode;
    },
    allowClose(state) {
      state.allowedClose = true;
    },
    playbackActions(state) {
      state.playing = true;
      state.errorRow = -1;
      state.currentRow = -1;
    },
    playbackActionsDone(state) {
      state.playing = false;
    },
    setCurrentRow(state, { payload: { key } }) {
      state.currentRow = key;
    },
    setErrorRow(state, { payload: { key } }) {
      state.errorRow = key;
    },
    getCoordinatesForSwipe(state, { payload: { status } }) {
      state.gettingCoordinates = status;
    },
    setSelectedElementId(
      state,
      { payload: { elementId, variableName, variableType } }
    ) {
      state.selectedElementId = elementId;
      state.selectedElementVariableName = variableName;
      state.selectedElementVariableType = variableType;
    },
    selectElement(state, { payload: { path, selectedElement } }) {
      state.selectedElement = selectedElement;
      state.selectedElementPath = path;
      state.selectedElementId = null;
      state.selectedElementVariableName = null;
      state.selectedElementVariableType = null;
      state.elementInteractionsNotAvailable = false;
    },
    unselectElement(state) {
      state.selectedElement = undefined;
      state.selectedElementPath = null;
      state.selectedElementId = null;
      state.selectedElementVariableName = null;
      state.selectedElementVariableType = null;
    },
    setExpandedPaths(state, { payload: { paths } }) {
      state.expandedPaths = paths;
    },
    selectHoveredElement(state, { payload: { hoveredElement } }) {
      state.hoveredElement = hoveredElement;
    },
    unselectHoveredElement(state) {
      state.hoveredElement = null;
    },
    setSearchedElementBounds(state, { payload: { location, size } }) {
      state.searchedElementBounds = { location, size };
    },
    clearSearchedElementBounds(state) {
      state.searchedElementBounds = null;
    },
    setInteractionsNotAvailable(state) {
      state.elementInteractionsNotAvailable = true;
    },
    searchElement(state) {
      state.searchedElements = [];
      state.selectedElementId = null;
      state.searching = true;
    },
    searchElementDone(state) {
      state.searching = false;
    },
    setSwipeStart(state, { payload: { x, y } }) {
      state.swipe.start = { x, y };
    },
    setSwipeEnd(state, { payload: { x, y } }) {
      state.swipe.end = { x, y };
    },
    // TODO: Specified for scroll and find drawer
    showScrollAndFindDrawer(state) {
      state.scrollAndFindDrawerVisible = true;
    },
    hideScrollAndFindDrawer(state) {
      state.scrollAndFindDrawerVisible = false;
      state.swipe = initialSwipe;
      state.searchedElementBounds = {};
    },

    // TODO: Specified for send keys drawer
    showSendKeysDrawer(state) {
      state.sendKeysDrawerVisible = true;
    },
    hideSendKeysDrawer(state) {
      state.sendKeysDrawerVisible = false;
    },
    // TODO: Specified for assert drawer
    showAssertDrawer(state) {
      state.assertDrawerVisible = true;
    },
    hideAssertDrawer(state) {
      state.assertDrawerVisible = false;
    },
    // TODO: Specified for assert drawer
    showClientDevicesDrawer(state) {
      state.clientDevicesDrawerVisible = true;
    },
    hideClientDeviceDrawer(state) {
      state.clientDevicesDrawerVisible = false;
    },
    // TODO: Searched elements drawer
    showSearchedElementsDrawer(state) {
      state.searchedElementsDrawerVisible = true;
    },
    hideSearchedElementsDrawer(state) {
      state.searchedElementsDrawerVisible = false;
    },
    // TODO: Specified for swipe by times drawer
    showSwipeByTimesDrawer(state) {
      state.swipeByTimesDrawerVisible = true;
    },
    hideSwipeByTimesDrawer(state) {
      state.swipeByTimesDrawerVisible = false;
      state.swipe.start = { x: null, y: null };
      state.swipe.end = { x: null, y: null };
      state.selectSwipePointsFrom = null;
      state.screenshotInteractionMode = 'select';
    },
    selectSwipePoints(state) {
      state.screenshotInteractionMode = 'swipe';
      state.swipeByTimesDrawerVisible = false;
      state.swipe = initialSwipe;
      state.selectSwipePointsFrom = 'swipe-drawer';
    },
    // TODO: Element detail
    showElementDetailDrawer(state) {
      state.elementDetailDrawerVisible = true;
    },
    hideElementDetailDrawer(state) {
      state.elementDetailDrawerVisible = false;
    },
    // TODO: Tap
    clearTapPoint: (state) => {
      state.tapPoint = initialTapPoint;
    },
    setTapPoint: (state, { payload: { x, y } }) => {
      state.tapPoint = { x, y };
    },
    selectSwipePointsFrom: (state, { payload: { from } }) => {
      state.selectSwipePointsFrom = from;
    },
    selectTapPointFrom: (state, { payload: { from } }) => {
      state.selectTapPointFrom = from;
    },
    updateElements: (state, { payload: { elements } }) => {
      state.elementData = keyBy(elements, 'id');
    },
    // TODO: Volume Drawer
    showAdjustVolumeDrawer(state) {
      state.adjustVolumeDrawerVisible = true;
    },
    hideAdjustVolumeDrawer(state) {
      state.adjustVolumeDrawerVisible = false;
    },
    // TODO: Switch app drawer
    showSwitchAppDrawer(state) {
      state.switchAppDrawerVisible = true;
    },
    hideSwitchAppDrawer(state) {
      state.switchAppDrawerVisible = false;
      state.selectedTargetApp = null;
    },
    showNewTargetAppDrawer(state) {
      state.newTargetAppDrawerVisible = true;
    },
    hideNewTargetAppDrawer(state) {
      state.newTargetAppDrawerVisible = false;
    },
    showEditTargetAppDrawer(state) {
      state.editTargetAppDrawerVisible = true;
    },
    hideEditTargetAppDrawer(state) {
      state.editTargetAppDrawerVisible = false;
    },
    setSelectedTargetApp(state, { payload: { appName } }) {
      state.selectedTargetApp = appName;
    },
    // TODO: APPIUM
    updateVariableStorage(state, { payload: { textStorage, variable } }) {
      state.variableStorage[variable] = textStorage;
    },
    methodCallRequested(state) {
      state.methodCallInProgress = true;
    },
    methodCallDone(state) {
      state.methodCallInProgress = false;
    },
    setSourceAndScreenshot(state, { payload }) {
      state.source = payload.source;
      state.sourceXML = payload.sourceXML;
      state.sourceError = payload.sourceError;
      state.screenshot = payload.screenshot;
      state.screenshotError = payload.screenshotError;
      state.windowSize = payload.windowSize;
      state.windowSizeError = payload.windowSizeError;
    },
    createAppiumSession(state) {
      state.sessionLoading = true;
    },
    createAppiumSessionDone(state) {
      state.sessionLoading = false;
      state.methodCallInProgress = false;
    },
    createAppiumSessionFailed(state) {
      state.sessionLoading = false;
      state.methodCallInProgress = false;
    },
    quitSessionDone: () => initialState,
    getInspectorData: () => {
      // Used in saga
    },
    addAppInfo: (state, { payload }) => {
      state.apps[payload.appName] = payload;
      state.saved = false;
    },
    deleteAppInfo: (state, { payload: { appName } }) => {
      delete state.apps[appName];
      state.saved = false;
    },

    // =========================================================================
    // TODO: ACTION FLOW
    // =========================================================================
    updateComponentData: (state, { payload: { data } }) => {
      const { id } = data;
      state.componentsData[id] = data;
      state.saved = false;
    },
    updateActionData: (state, { payload: { data } }) => {
      const { id, ...updatedData } = data;
      state.pureElementsData[id].data = {
        ...state.pureElementsData[id].data,
        ...updatedData,
      };
      state.saved = false;
    },
    setPureElements: (state, { payload: { elements } }) => {
      const pureElementsData = keyBy(elements, 'id');
      state.pureElementsData = pureElementsData;
      state.saved = false;
    },
    addNewElements: (state, { payload: { newElements } }) => {
      newElements.forEach((el) => {
        state.pureElementsData[el.id] = el;
      });
      state.saved = false;
    },
    setComponentsData: (state, { payload: { componentsData } }) => {
      state.componentsData = componentsData;
      state.saved = false;
    },
    removeAllActions: (state) => {
      state.pureElementsData = initialPureElement;
      state.componentsData = {};
      state.saved = false;
    },
    setZoom: (state, { payload: { zoom } }) => {
      state.zoom = zoom;
    },
  },
  extraReducers: {
    [getTestCaseDetailSuccess]: (
      state,
      {
        payload: {
          recordedActions = [],
          variableStorage = {},
          pureElementsData = initialPureElement,
          componentsData = {},
          apps = {},
        },
      }
    ) => {
      // TODO: For version 1.2.0 and prior
      if (
        recordedActions.length > 0 &&
        isEqual(pureElementsData, initialPureElement)
      ) {
        const result = convertRecordedActions(recordedActions, variableStorage);
        state.pureElementsData = result.pureElementsData;
        state.componentsData = result.componentsData;
      } else {
        state.pureElementsData = pureElementsData;
        state.componentsData = componentsData;
      }
      state.variableStorage = variableStorage;
      state.saved = true;
      state.allowedClose = false;
      state.apps = apps;
    },
    [updateTestCaseSuccess]: (state) => {
      state.saved = true;
    },
    [updateTestCaseFailure]: (state) => {
      state.saved = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

// =========================================================================
// TODO: ACTION FLOW THUNKS
// =========================================================================
export const removeActions = ({ elementsToRemove, internalElements }) => (
  dispatch
) => {
  const elements = removeCustomElements(elementsToRemove, internalElements);
  dispatch(actions.setPureElements({ elements }));
};

const recordAction = ({ action, params }) => (dispatch, getState) => {
  const key = uuidv4();
  const recordedAction = {
    key,
    action,
    params,
  };

  const state = getState();
  const variableStorage = variableStorageSelector(state);
  const pureElementsData = pureElementsDataSelector(state);
  const componentsData = componentsDataSelector(state);
  const { platformName } = currentTestSuiteSelector(state);

  // Remove END element
  const { end } = pureElementsData;
  const elements = Object.values(pureElementsData);
  dispatch(
    removeActions({ elementsToRemove: [end], internalElements: elements })
  );

  // Update component data
  const index = Object.keys(componentsData).length;
  const component = getComponent(recordedAction, index);
  const draftComponentsData = { ...componentsData };

  if (component) {
    draftComponentsData[key] = component;
    dispatch(
      actions.setComponentsData({ componentsData: draftComponentsData })
    );
  }

  // Add new elements
  const newAction = parseRecordedAction(
    platformName,
    recordedAction,
    draftComponentsData,
    variableStorage
  );

  newAction.position = end.position;
  const newEnd = {
    ...end,
    position: { x: end.position.x, y: end.position.y + 100 },
  };

  let newElements = [
    newAction,
    {
      id: `e-${newAction.id}-${newEnd.id}`,
      source: newAction.id,
      target: newEnd.id,
      arrowHeadType: 'arrowclosed',
    },
    newEnd,
  ];

  const [incomer] = getIncomers(end, elements);
  if (incomer) {
    newElements = [
      {
        id: `e-${incomer.id}-${newAction.id}`,
        source: incomer.id,
        target: newAction.id,
        arrowHeadType: 'arrowclosed',
      },
      ...newElements,
    ];
  }

  dispatch(actions.addNewElements({ newElements }));
};

/**
 * Called when user connects two nodes
 * @param {Object} params
 * @param {Object} params.source
 * @param {Object} params.target
 * @param {Object[]} currentElements Element get from action-flow store (with current position)
 */
export const connectActions = (params, currentElements) => (dispatch) => {
  const elements = addCustomEdge(params, currentElements);
  dispatch(actions.setPureElements({ elements }));
};

export const updateEdgeConnection = ({
  oldEdge,
  newConnection,
  internalElements,
}) => (dispatch) => {
  const elements = updateCustomEdge(oldEdge, newConnection, internalElements);

  dispatch(actions.setPureElements({ elements }));
};

// =========================================================================
// TODO: APPIUM THUNKS
// =========================================================================
export function findAndAssign({ strategy, selector, variableName, isArray }) {
  return (dispatch, getState) => {
    const variableComponentIdPair = variableComponentIdPairSelector(getState());

    // If this call to 'findAndAssign' for this variable wasn't done already, do it now
    if (!variableComponentIdPair[variableName]) {
      dispatch(
        recordAction({
          action: 'findAndAssign',
          params: [strategy, selector, variableName, isArray],
        })
      );
    }
  };
}

// TODO: RECORDING
export function recordScrollWithSpecificCoordinates(
  variableName,
  variableIndex,
  swipe
) {
  return (dispatch) =>
    dispatch(
      recordAction({
        action: 'scrollWithSpecificCoordinates',
        params: [variableName, variableIndex, swipe],
      })
    );
}

export function recordScrollAndFindUntilVisible(
  variableName,
  variableIndex,
  search
) {
  return (dispatch) => {
    if (variableName) {
      dispatch(
        recordAction({
          action: 'scrollAndFindUntilVisible',
          params: [variableName, variableIndex, search],
        })
      );
    }
  };
}

/**
 * Record sleeping time in seconds
 * @param {*} seconds
 */
export function recordSleep(seconds) {
  return (dispatch, getState) => {
    const recording = recordingSelector(getState());
    if (recording) {
      dispatch(recordAction({ action: 'sleep', params: [seconds] }));
    }
  };
}

let methodHandler;

/**
 * Dispatch session done
 */
export function appiumSessionDone(reason, killedByUser) {
  return (dispatch) => {
    const language = window.localStorage.getItem('gatsby-i18next-language');

    // TODO: Match router to get test case id in path
    const { params } =
      matchRouter(
        // eslint-disable-next-line prettier/prettier
        `${language !== 'en' ? `/${language}` : ''}/dashboard/inspector/:testCaseId`
      ) || {};

    const { testCaseId } = params || {};

    if (killedByUser) {
      dispatch(actions.quitSessionDone());

      // TODO: Prevent case user doesn't click on close button
      if (testCaseId) {
        navigate(
          // eslint-disable-next-line prettier/prettier
          `${language !== 'en' ? `/${language}` : ''}/dashboard/test-cases/${testCaseId}`
        );
      } else {
        navigate(
          // eslint-disable-next-line prettier/prettier
          `${language && language !== 'en' ? `/${language}` : ''}/dashboard/projects`
        );
      }
    } else {
      notification.error({
        message: 'Error',
        description:
          reason ||
          'Session has been terminated. (Check whether sever has started or not)',
        duration: 10,
      });
    }
  };
}

/**
 * Kill session associated with session browser window
 */
function killSession(killedByUser = false) {
  return (dispatch) => {
    if (methodHandler) {
      methodHandler.close();
    }
    dispatch(appiumSessionDone('', killedByUser));
    methodHandler = null;
  };
}

const clientMethodPromises = {};

/**
 * When we hear back from the main process, resolve the promise
 */
function appiumClientCommandResponse(resp) {
  return () => {
    // TODO: Rename 'id' to 'elementId'
    resp.elementId = resp.id;
    const promise = clientMethodPromises[resp.uuid];
    if (promise) {
      promise.resolve(resp);
      delete clientMethodPromises[resp.uuid];
    }
  };
}

/**
 * If we hear back with an error, reject the promise
 */
function appiumClientCommandResponseError(resp) {
  return () => {
    const { e, uuid } = resp;
    const promise = clientMethodPromises[uuid];
    if (promise) {
      promise.reject(e);
      delete clientMethodPromises[uuid];
    }
  };
}

/**
 * Get element variable counter (el1, el2, el3, etc)
 * @param {array} variables
 */
export function getVariableCounter(variables = []) {
  // Get max element counter from recorded action
  const regex = /^el(\d+)$/; // TODO: Match el1, el2, el9991
  const elCounters = variables
    .map((elVar) => {
      if (typeof elVar === 'string') {
        return parseInt(elVar.match(regex)?.[1]);
      }
      return null;
    })
    .filter((count) => Number.isInteger(count));
  if (elCounters?.length > 0) {
    // TODO: Reset element counter
    return Math.max(...elCounters);
  }
  return 0;
}

/**
 * When a Session Window makes method request,
 * find it's corresponding driver, execute requested method
 * and send back the result
 */
function appiumClientCommandRequest(data) {
  return async (dispatch, getState) => {
    let {
      methodName, // Optional. Name of method being provided
    } = data;
    const {
      uuid, // Transaction ID
      strategy, // Optional. Element locator strategy
      selector, // Optional. Element fetch selector
      fetchArray = false, // Optional. Are we fetching an array of elements or just one?
      elementId, // Optional. Element being operated on
      args = [], // Optional. Arguments passed to method
      skipScreenshotAndSource = false, // Optional. Do we want the updated source and screenshot?
      skipExec = false, // Optional. In case do not want execute command
      search, // Optional. Option contains information related to scroll & find
      swipe,
    } = data;

    if (methodHandler) {
      // TODO: Update element variable counter
      const state = getState();
      const usedVariables = usedVariablesSelector(state);
      methodHandler.elVariableCounter = getVariableCounter(usedVariables);

      // TODO: Update element variable storage
      methodHandler.variableStorage = variableStorageSelector(state);
    }

    if (!methodHandler) {
      methodName = 'quit';
    }

    try {
      if (methodName === 'quit') {
        // console.log("Handling client method request with method 'quit'");
        await dispatch(killSession(true));
        // TODO: when we've quit the session, there's no source/screenshot to send back
        dispatch(
          appiumClientCommandResponse({
            source: null,
            screenshot: null,
            windowSize: null,
            uuid,
            result: null,
          })
        );
      } else {
        let res = {};
        if (methodName) {
          if (elementId) {
            res = await methodHandler.executeElementCommand(
              elementId,
              methodName,
              args,
              skipScreenshotAndSource,
              skipExec
            );
          } else {
            res = await methodHandler.executeMethod(
              methodName,
              args,
              skipScreenshotAndSource
            );
          }
        } else if (strategy && selector) {
          if (fetchArray) {
            res = await methodHandler.fetchElements(strategy, selector);
          } else {
            res = await methodHandler.fetchElement(strategy, selector);
          }
        } else if (search) {
          res = await methodHandler.scrollAndFindUntilVisible(
            search,
            skipScreenshotAndSource
          );
        } else if (swipe) {
          res = await methodHandler.scrollWithSpecificCoordinates(
            swipe,
            skipScreenshotAndSource
          );
        }

        dispatch(
          appiumClientCommandResponse({
            ...res,
            uuid,
          })
        );
      }
    } catch (e) {
      // TODO: If the status is '6' that means the session has been terminated
      if (e.status === 6) {
        message.error('e.status = 6');
      }
      dispatch(
        appiumClientCommandResponseError({
          e,
          uuid,
        })
      );
    }
  };
}

export function callClientMethod(params) {
  return (dispatch) => {
    const uuid = uuidv4();
    const promise = new Promise((resolve, reject) => {
      clientMethodPromises[uuid] = { resolve, reject };
    });
    dispatch(
      appiumClientCommandRequest({
        ...params,
        uuid,
      })
    );
    return promise;
  };
}

/**
 * Requests a method call on Appium
 */
export function applyClientMethod(params) {
  return async (dispatch, getState) => {
    const recording = recordingSelector(getState());
    const playing = playingSelector(getState());
    const { methodName, args, skipScreenshotAndSource } = params;
    const isRecording =
      methodName !== 'quit' && methodName !== 'source' && recording && !playing;
    try {
      dispatch(actions.methodCallRequested());
      // TODO: FOR DEBUG: CALL CLIENT METHOD (IMPORTANT)
      const exec = await dispatch(callClientMethod(params));
      const {
        source,
        screenshot,
        windowSize,
        // result,
        sourceError,
        screenshotError,
        windowSizeError,
        variableName,
        variableIndex,
        strategy,
        selector,
        search, // TODO: search object, contain strategy, value and variable
        swipe,
      } = exec;
      if (isRecording) {
        if (search) {
          dispatch(
            recordScrollAndFindUntilVisible(variableName, variableIndex, search)
          );
        } else if (swipe) {
          dispatch(
            recordScrollWithSpecificCoordinates(
              variableName,
              variableIndex,
              swipe
            )
          );
        } else if (
          strategy &&
          selector &&
          !variableIndex &&
          variableIndex !== 0
        ) {
          // TODO: Add 'findAndAssign' line of code.
          // TODO: Don't do it for arrays though. Arrays already have 'find' expression
          dispatch(
            findAndAssign({ strategy, selector, variableName, isArray: false })
          );
        }

        // TODO: now record the actual action
        if (methodName) {
          let _args = [variableName, variableIndex];
          _args = _args.concat(args || []);
          dispatch(recordAction({ action: methodName, params: _args }));
        }

        // TODO: Add action sleep after taking time action
        if (
          [
            'click',
            'tap',
            'swipe',
            'back',
            'pressPhysicalButton',
            'switchApp',
          ].indexOf(params.methodName) > -1
        ) {
          dispatch(recordAction({ action: 'sleep', params: [3] }));
        }
      }

      dispatch(actions.methodCallDone());

      const json = source && xmlToJSON(source);

      if (!skipScreenshotAndSource) {
        dispatch(
          actions.setSourceAndScreenshot({
            source: json,
            sourceXML: source,
            screenshot,
            windowSize,
            sourceError,
            screenshotError,
            windowSizeError,
          })
        );
      }

      return exec;
    } catch (error) {
      showErrorAppium(error, methodName, 0);

      dispatch(actions.methodCallDone());
      throw error;
    }
  };
}

/**
 * Start Appium Session
 */
export function createNewAppiumSession() {
  return async (dispatch, getState) => {
    dispatch(actions.hideClientDeviceDrawer());

    dispatch(actions.createAppiumSession());

    const hubUrl = hubUrlSelector(getState());

    const desiredCapabilities = capabilitiesSelector(getState());

    try {
      // If there is an already active session, kill it. Limit one session per window.
      if (methodHandler) {
        await dispatch(killSession());
      }
      // Create the driver and cache it by the sender ID
      const driver = promiseChainRemote(`${hubUrl}/wd/hub`);

      methodHandler = new AppiumMethodHandler(driver);

      const isAndroid =
        desiredCapabilities?.platformName?.toLowerCase() === 'android';
      const isIOS = desiredCapabilities.platformName.toLowerCase() === 'ios';

      if (isIOS) {
        desiredCapabilities.automationName = 'XCUITest';
        desiredCapabilities.connectHardwareKeyboard = false;
        desiredCapabilities.shouldUseSingletonTestManager = false;

        // TODO: Fix error: connect ECONNREFUSED 127.0.0.1:8100
        // Number of times to try to build and
        // launch WebDriverAgent onto the device. Defaults to 2.
        desiredCapabilities.wdaStartupRetries = 4;
        // Time, in ms, to wait between tries to build
        // and launch WebDriverAgent. Defaults to 10000ms.
        desiredCapabilities.wdaStartupRetryInterval = 20000;

        // Show all Xcode Log for real device to debug issue
        desiredCapabilities.showXcodeLog = true;

        // TODO: Optimizing WebDriverAgent Startup Performance
        // https://redmine.humannext.co.jp/issues/39009
        desiredCapabilities.noReset = true;
      } else if (isAndroid) {
        desiredCapabilities.automationName = 'UiAutomator2';
        // TODO: Input Unicode
        desiredCapabilities.unicodeKeyboard = true;

        // TODO: Have Appium automatically determine which permissions your
        // app requires and grant them to the app on install. Defaults to false.
        // If noReset is true, this capability doesn't work.
        desiredCapabilities.autoGrantPermissions = true;

        desiredCapabilities.resetKeyboard = true;

        // TODO: Fix this error for real device
        // https://github.com/appium/appium/blob/master/docs/en/writing-running-appium/android/activity-startup.md#commyactivity-or-commyappcommyactivity-never-started
        desiredCapabilities.appWaitDuration = 60000;
      }

      desiredCapabilities.newCommandTimeout = 0;
      desiredCapabilities.clearSystemFiles = true;

      // TODO: Should not add waitForQuiescence from version 1.9.0.
      // Cause making app not start
      // desiredCapabilities.waitForQuiescence = false;

      // TODO: MAIN THING
      // TODO: Try initializing it. If it fails, kill it and send error message to sender
      await methodHandler.init(desiredCapabilities);

      // TODO: Fetch screenshot
      if (isAndroid) {
        await delay(1000);
      }
      dispatch(applyClientMethod({ methodName: 'source' }));

      // if (hostname !== '127.0.0.1' && hostname !== 'localhost') {
      methodHandler.runKeepAliveLoop();
      // }

      // we don't really support the web portion of apps for a number of
      // reasons, so pre-emptively ensure we're in native mode before doing the
      // rest of the inspector startup. Since some platforms might not implement
      // contexts, ignore any failures here.
      try {
        await driver.context('NATIVE_APP');
      } catch (ign) {
        // TODO: Do nothing
      }

      dispatch(actions.createAppiumSessionDone());
    } catch (error) {
      dispatch(actions.showClientDevicesDrawer());

      // If the session failed, delete it from the cache
      dispatch(killSession());

      // If it failed, show an alert saying it failed
      dispatch(actions.createAppiumSessionFailed(error));

      showErrorAppium(error);
    }
  };
}

/**
 * Quit the session and go back to the new session window
 */
export function quitSession() {
  return async (dispatch) => {
    await dispatch(applyClientMethod({ methodName: 'quit' }));
  };
}

/**
 * Execute all recorded actions or selected actions
 * @param {*} transform Use transform of React flow
 * @param {*} setSelectedElements Use setSelectedElements of React flow
 */
export function playbackActions(transform, setSelectedElements) {
  return async (dispatch, getState) => {
    const state = getState();
    const pureElements = pureElementsSelector(state);
    const componentsData = componentsDataSelector(state);
    const { platformName } = currentTestSuiteSelector(state);

    const recordedActions = convertPureElements(
      platformName,
      pureElements,
      componentsData
    );

    let currentActionKey = -1;

    try {
      dispatch(actions.playbackActions());

      await dispatch(applyClientMethod({ methodName: 'resetApp' }));

      const elements = {};

      // TODO: Reset current element counter
      for await (const { key, action, params, position } of recordedActions) {
        currentActionKey = key;
        // TODO: Set current row to draw highlight
        dispatch(actions.setCurrentRow({ key: currentActionKey }));
        setSelectedElements([{ id: key }]);

        transform({ x: -position.x + 150, y: -position.y, zoom: 1 });

        // TODO: Assign action for each function
        switch (action) {
          case 'findAndAssign': {
            const [strategy, selector, elementVar] = params;

            const response = await dispatch(
              callClientMethod({
                strategy,
                selector,
              })
            );

            elements[elementVar] = response.elementId;
            break;
          }
          case 'click':
          case 'clear': {
            const [elementVar] = params;
            await dispatch(
              applyClientMethod({
                methodName: action,
                elementId: elements[elementVar],
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'longPress': {
            const [elementVar, , seconds] = params;

            await dispatch(
              applyClientMethod({
                methodName: 'longPress',
                elementId: elements[elementVar],
                args: [seconds],
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'sendKeys': {
            const [
              elementVar,
              ,
              sendKeysValue,
              sendKeysVariable,
              sendKeysAction,
            ] = params;
            await dispatch(
              applyClientMethod({
                methodName: 'sendKeys',
                elementId: elements[elementVar],
                args: [sendKeysValue, sendKeysVariable, sendKeysAction],
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'sleep': {
            const [seconds] = params;
            await delay(seconds * 1000);
            break;
          }
          case 'text': {
            const [
              elementVar,
              ,
              assertValue,
              assertVariable,
              assertType,
            ] = params;
            await dispatch(
              applyClientMethod({
                methodName: 'text',
                elementId: elements[elementVar],
                args: [assertValue, assertVariable, assertType],
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'scrollAndFindUntilVisible': {
            const [elementVar, , search] = params;

            const response = await dispatch(
              applyClientMethod({
                search,
                skipScreenshotAndSource: true,
              })
            );

            if (response.elementId) {
              elements[elementVar] = response.elementId;
            }
            break;
          }
          case 'scrollWithSpecificCoordinates': {
            const [, , swipe] = params;
            await dispatch(
              applyClientMethod({
                swipe,
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'pressPhysicalButton': {
            const [, , buttonName, times] = params;
            await dispatch(
              applyClientMethod({
                methodName: 'pressPhysicalButton',
                args: [buttonName, times],
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          case 'switchApp': {
            const [, , ...args] = params;
            await dispatch(
              applyClientMethod({
                methodName: 'switchApp',
                args,
                skipScreenshotAndSource: true,
              })
            );
            break;
          }
          default:
            await dispatch(
              applyClientMethod({
                methodName: action,
                args: params.slice(2),
                skipScreenshotAndSource: true,
              })
            );
            break;
        }
      }
      // TODO: Reset table if don't have any error occur
      dispatch(actions.setErrorRow({ key: -1 }));
      notification.success({
        message: 'Success',
        description: 'All actions have been done.',
        duration: 0,
      });

      dispatch(actions.playbackActionsDone());
    } catch (error) {
      console.error('Playback', error);
      notification.error({
        message: 'Failure',
        description: 'Check failed action and run again.',
        duration: 10,
      });
      dispatch(actions.setErrorRow({ key: currentActionKey }));
      dispatch(actions.playbackActionsDone());
    }
    // TODO: Refresh screenshot before quit
    await dispatch(
      applyClientMethod({
        methodName: 'source',
      })
    );
  };
}

// A debounced function that calls findElement and gets info about the element
const findElement = debounce(async (strategyMap, path, dispatch, getState) => {
  for await (const [strategy, selector] of strategyMap) {
    // Get the information about the element
    const { elementId, variableName, variableType } = await dispatch(
      callClientMethod({
        strategy,
        selector,
      })
    );

    // Set the elementId, variableName and variableType for the selected element
    // (check first that the selectedElementPath didn't change, to avoid race conditions)
    const selectedElementPath = selectedElementPathSelector(getState());
    if (elementId && selectedElementPath === path) {
      return dispatch(
        actions.setSelectedElementId({
          elementId,
          variableName,
          variableType,
        })
      );
    }
  }

  return dispatch(actions.setInteractionsNotAvailable());
}, 2000);

export function selectElement({ path }) {
  return async (dispatch, getState) => {
    const source = sourceSelector(getState());
    const selectedElement = findElementByPath(path, source);
    const sourceXML = sourceXMLSelector(getState());

    // Set the selected element in the source tree
    dispatch(actions.selectElement({ path, selectedElement }));

    const {
      attributes: selectedElementAttributes,
      xpath: selectedElementXPath,
    } = selectedElement;

    // Expand all of this element's ancestors so that it's visible in the source tree
    let expandedPaths = expandedPathsSelector(getState());
    const pathArr = path.split('.').slice(0, path.length - 1);
    while (pathArr.length > 1) {
      pathArr.splice(pathArr.length - 1);
      const vPath = pathArr.join('.');
      if (expandedPaths.indexOf(vPath) < 0) {
        // expandedPaths.push(vPath);
        expandedPaths = [...expandedPaths, vPath];
      }
    }
    dispatch(actions.setExpandedPaths({ paths: expandedPaths }));

    // Find the optimal selection strategy. If none found, fall back to XPath.
    const strategyMap = toPairs(
      getLocators(selectedElementAttributes, sourceXML)
    );
    strategyMap.push(['xpath', selectedElementXPath]);

    // Debounce find element so that if another element is selected shortly after, cancel the previous search
    // SHOULD UN COMMENT
    await findElement(strategyMap, path, dispatch, getState);
  };
}

export function selectHoveredElement({ path }) {
  return (dispatch, getState) => {
    const source = sourceSelector(getState());
    const hoveredElement = findElementByPath(path, source);
    dispatch(actions.selectHoveredElement({ hoveredElement }));
  };
}

export function scrollWithSpecificCoordinates({ start, end, maxSwipe = 1 }) {
  return async (dispatch) => {
    await dispatch(applyClientMethod({ swipe: { start, end, maxSwipe } }));
  };
}

export function setElementLocator({ elementId }) {
  return async (dispatch) => {
    dispatch(actions.setSelectedElementId({ elementId }));

    dispatch(actions.clearSearchedElementBounds());
    if (elementId) {
      try {
        const [location, size] = await Promise.all([
          dispatch(
            callClientMethod({
              methodName: 'getLocation',
              args: [elementId],
              skipScreenshotAndSource: true,
              skipRecord: true,
            })
          ),
          dispatch(
            callClientMethod({
              methodName: 'getSize',
              args: [elementId],
              skipScreenshotAndSource: true,
              skipRecord: true,
            })
          ),
        ]);
        dispatch(
          actions.setSearchedElementBounds({
            location: location.res,
            size: size.res,
          })
        );
      } catch (ign) {
        // Do something
      }
    }
  };
}

export function searchForElement({ strategy, selector }) {
  return async (dispatch) => {
    dispatch(actions.searchElement());
    try {
      const { elementId } = await dispatch(
        callClientMethod({
          strategy,
          selector,
        })
      );
      dispatch(setElementLocator({ elementId }));
      dispatch(actions.searchElementDone());
      dispatch(actions.showSearchedElementsDrawer());
    } catch (error) {
      dispatch(actions.searchElementDone());
      throw error;
    }
  };
}

export function scrollAndFindUntilVisible({
  criteria,
  value,
  variable,
  maxTries,
}) {
  return async (dispatch) => {
    dispatch(actions.searchElement());
    try {
      const exec = await dispatch(
        applyClientMethod({
          search: { criteria, value, variable, maxTries },
        })
      );

      dispatch(setElementLocator({ elementId: exec.id }));

      dispatch(actions.searchElementDone());

      dispatch(actions.showSearchedElementsDrawer());
    } catch (error) {
      dispatch(actions.searchElementDone());
      throw error;
    }
  };
}

export const pressSelectedElement = () => (dispatch, getState) => {
  dispatch(actions.clearSearchedElementBounds());
  const elementId = selectedElementIdSelector(getState());
  dispatch(
    applyClientMethod({
      methodName: 'click',
      elementId,
    })
  );
};

export const longPressSelectedElement = (seconds) => (dispatch, getState) => {
  dispatch(actions.clearSearchedElementBounds());
  const elementId = selectedElementIdSelector(getState());
  dispatch(
    applyClientMethod({
      methodName: 'longPress',
      elementId,
      args: [seconds],
    })
  );
};

export const clearSelectedElement = () => (dispatch, getState) => {
  const elementId = selectedElementIdSelector(getState());

  dispatch(
    applyClientMethod({
      methodName: 'clear',
      elementId,
    })
  );
};

export const save = (testCaseId) => (dispatch, getState) => {
  const state = getState();
  const pureElements = pureElementsSelector(state);
  const pureElementsData = pureElementsDataSelector(state);
  const variableStorage = variableStorageSelector(state);
  const componentsData = componentsDataSelector(state);
  const { platformName } = currentTestSuiteSelector(state);
  const recordedActions = convertPureElements(
    platformName,
    pureElements,
    componentsData
  );
  const apps = appsSelector(state);

  dispatch(
    updateTestCase({
      id: testCaseId,
      recordedActions,
      variableStorage,
      pureElementsData,
      componentsData,
      apps,
    })
  );
};

const pressPhysicalButton = (buttonName, times = 1) => (dispatch) => {
  dispatch(
    applyClientMethod({
      methodName: 'pressPhysicalButton',
      args: [buttonName, times],
    })
  );
};

export const pressHomeButton = () => (dispatch) =>
  dispatch(pressPhysicalButton('home'));

export const pressVolumeUpButton = (times) => (dispatch) =>
  dispatch(pressPhysicalButton('volumeUp', times));

export const pressVolumeDownButton = (times) => (dispatch) =>
  dispatch(pressPhysicalButton('volumeDown', times));

export const SelectedElement = (seconds) => (dispatch, getState) => {
  dispatch(actions.clearSearchedElementBounds());
  const elementId = selectedElementIdSelector(getState());
  dispatch(
    applyClientMethod({
      methodName: 'longPress',
      elementId,
      args: [seconds],
    })
  );
};

/**
 * @param {string} args: iOS = [bundleId], android = [appPackage, appActivity]
 */
export const switchApp = (args) => (dispatch) => {
  dispatch(
    applyClientMethod({
      methodName: 'switchApp',
      args,
    })
  );
};

export const {
  clearSwipeAction,
  selectScreenshotInteractionMode,
  startRecording,
  pauseRecording,
  allowClose,
  getCoordinatesForSwipe,
  unselectElement,
  showSendKeysDrawer,
  hideSendKeysDrawer,
  unselectHoveredElement,
  setSwipeStart,
  setSwipeEnd,
  showScrollAndFindDrawer,
  hideScrollAndFindDrawer,
  selectSwipePoints,
  showAssertDrawer,
  hideAssertDrawer,
  showClientDevicesDrawer,
  hideClientDeviceDrawer,
  setSelectedElementId,
  hideSearchedElementsDrawer,
  setExpandedPaths,
  showSwipeByTimesDrawer,
  hideSwipeByTimesDrawer,
  clearTapPoint,
  selectTapPointFrom,
  setTapPoint,
  updateComponentData,
  updateActionData,
  createAppiumSessionDone,
  getInspectorData,
  updateVariableStorage,
  setZoom,
  setPureElements,
  removeAllActions,
  showElementDetailDrawer,
  hideElementDetailDrawer,
  showAdjustVolumeDrawer,
  hideAdjustVolumeDrawer,
  showSwitchAppDrawer,
  hideSwitchAppDrawer,
  addAppInfo,
  deleteAppInfo,
  showNewTargetAppDrawer,
  hideNewTargetAppDrawer,
  showEditTargetAppDrawer,
  hideEditTargetAppDrawer,
  setSelectedTargetApp,
  quitSessionDone,
} = actions;

export default reducer;

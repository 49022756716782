import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';

const slice = createSlice({
  name: 'jobs',
  initialState: {
    currentId: null,
    data: {},
    ids: [],
    loading: false,
  },
  reducers: {
    // TODO: GET ALL
    getJobs(state) {
      state.loading = true;
    },
    getJobsSuccess(state, { payload: { ids, data, $refresh } }) {
      state.ids = $refresh ? ids : union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getJobsFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getJobDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getJobDetailSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    getJobDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE
    createJob(state) {
      state.loading = true;
    },
    createJobSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
      state.loading = false;
    },
    createJobFailure(state) {
      state.loading = false;
    },
    // TODO: UPDATE
    updateJob(state) {
      state.loading = true;
    },
    updateJobSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
      state.loading = false;
    },
    updateJobFailure(state) {
      state.loading = true;
    },
    // TODO: DELETE
    deleteJob(state) {
      state.loading = true;
    },
    deleteJobSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteJobFailure(state) {
      state.loading = false;
    },
    // TODO: Run Jobs
    runJob() {
      // Used in saga
    },
    runJobSuccess() {
      // Used in saga
    },
    runJobFailure() {
      // Used in saga
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = slice;

export const {
  getJobs,
  getJobsSuccess,
  getJobsFailure,
  getJobDetail,
  getJobDetailSuccess,
  getJobDetailFailure,
  createJob,
  createJobSuccess,
  createJobFailure,
  updateJob,
  updateJobSuccess,
  updateJobFailure,
  deleteJob,
  deleteJobSuccess,
  deleteJobFailure,
  runJob,
  runJobSuccess,
  runJobFailure,
} = actions;

// TODO: Thunks

export default reducer;

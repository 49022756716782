import { takeLatest, call, put } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as JobAPIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';
import {
  getJobs,
  createJob,
  updateJob,
  getJobDetail,
  deleteJob,
  getJobsSuccess,
  getJobsFailure,
  createJobSuccess,
  createJobFailure,
  updateJobSuccess,
  updateJobFailure,
  getJobDetailSuccess,
  getJobDetailFailure,
  deleteJobSuccess,
  deleteJobFailure,
} from './slice';

export function* getJobsWorker({ payload: { id, $filters, $refresh } }) {
  try {
    const response = yield call(apiCaller, JobAPIs.getJobs, { id }, $filters);

    const { ids, data } = convertGetAllData(response.data);
    yield put(getJobsSuccess({ ids, data, $refresh }));
  } catch (err) {
    yield put(getJobsFailure(err));
    showError(err);
  }
}

export function* createJobWorker({ payload, [DEFERRED]: { resolve, reject } }) {
  try {
    const { testSuiteId, ...data } = payload;
    const response = yield call(
      apiCaller,
      JobAPIs.createJob,
      { testSuiteId },
      data
    );
    yield put(createJobSuccess(response.data));
    resolve(response.data);
  } catch (err) {
    yield put(createJobFailure(err));
    showError(err);
    reject(err);
  }
}

export function* updateJobWorker({ payload, [DEFERRED]: { resolve, reject } }) {
  try {
    const { id, ...data } = payload;

    const result = yield call(apiCaller, JobAPIs.updateJob, { id }, data);
    yield put(updateJobSuccess(result.data));
    resolve(data);
  } catch (err) {
    yield put(updateJobFailure(err));
    showError(err);
    reject(err);
  }
}

export function* getJobDetailWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const response = yield call(apiCaller, JobAPIs.getJob, payload);
    yield put(getJobDetailSuccess(response.data));
    resolve(response.data);
  } catch (err) {
    yield put(getJobDetailFailure(err));
    showError(err);
    reject(err);
  }
}

export function* deleteJobWorker({ payload, [DEFERRED]: { resolve, reject } }) {
  try {
    const response = yield call(apiCaller, JobAPIs.deleteJob, payload);
    yield put(deleteJobSuccess(response.data));
    resolve(response.data);
  } catch (err) {
    yield put(deleteJobFailure(err));
    showError(err);
    reject(err);
  }
}

export default [
  takeLatest(getJobs, getJobsWorker),
  takeLatest(createJob, createJobWorker),
  takeLatest(updateJob, updateJobWorker),
  takeLatest(getJobDetail, getJobDetailWorker),
  takeLatest(deleteJob, deleteJobWorker),
];

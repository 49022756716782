import { useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';

function useDeepCompareMemoize(value) {
  const ref = useRef();
  const signalRef = useRef(0);
  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
}

export default function useDeepCompareEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

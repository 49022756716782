import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';

const slice = createSlice({
  name: 'apple-teams',
  initialState: {
    current: {},
    data: {},
    ids: [],
    loading: false,
    containers: {},
  },
  reducers: {
    // TODO: GET ALL
    getAppleTeams(state) {
      state.loading = true;
    },
    getAppleTeamsSuccess(state, { payload: { ids, data } }) {
      state.ids = union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getAppleTeamsFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getAppleTeamDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getAppleTeamDetailSuccess(state, { payload }) {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
      state.loading = false;
    },
    getAppleTeamDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE
    createAppleTeam(state) {
      state.loading = true;
    },
    createAppleTeamSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
      state.loading = false;
    },
    createAppleTeamFailure(state) {
      state.loading = false;
    },
    // TODO: UPDATE
    updateAppleTeam(state) {
      state.loading = true;
    },
    updateAppleTeamSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    updateAppleTeamFailure(state) {
      state.loading = true;
    },
    // TODO: DELETE
    deleteAppleTeam(state) {
      state.loading = true;
    },
    deleteAppleTeamSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteAppleTeamFailure(state) {
      state.loading = false;
    },
    removeCurrentAppleTeam(state) {
      state.currentId = null;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getAppleTeams,
  getAppleTeamsSuccess,
  getAppleTeamsFailure,
  getAppleTeamDetail,
  getAppleTeamDetailSuccess,
  getAppleTeamDetailFailure,
  createAppleTeam,
  createAppleTeamSuccess,
  createAppleTeamFailure,
  updateAppleTeam,
  updateAppleTeamSuccess,
  updateAppleTeamFailure,
  deleteAppleTeam,
  deleteAppleTeamSuccess,
  deleteAppleTeamFailure,
  removeCurrentAppleTeam,
} = actions;

export default reducer;

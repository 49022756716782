import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from 'redux/reducers'; // Or wherever you keep your reducers
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'redux/sagas';
import deferred from './deferredMiddleware';

// TODO: Middlewares
// TODO: Build the middleware for intercepting and dispatching navigation actions
const saga = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({}),
  deferred, // This middleware should go before saga and logger
  logger,
  saga,
];

export const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Run the saga
saga.run(rootSaga);

export default { store };

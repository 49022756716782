export default function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const waitUntil = async (conditionCallback) => {
  const promise = await new Promise((resolve) => {
    const interval = setInterval(() => {
      if (conditionCallback) {
        resolve();
        clearInterval(interval);
      }
    }, 1000);
  });
  return promise;
};

import { memoize } from 'lodash';
import {
  currentDataSelectorFactory,
  listSelectorFactory,
  loadingSelectorFactory,
} from 'redux/selectors';
import { createSelector } from 'reselect';

const KEY = 'files';

export const currentFileSelector = currentDataSelectorFactory(KEY);

export const filesSelector = createSelector(listSelectorFactory(KEY), (files) =>
  memoize((fileType) => {
    if (fileType) {
      return files.filter(({ type }) => type === fileType);
    }
    return files;
  })
);

export const fileLoadingSelector = loadingSelectorFactory(KEY);

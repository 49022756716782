import { useEffect, useState } from 'react';

export default function usePlatform() {
  const [isMac, setIsMac] = useState(false);
  const [isWin, setIsWin] = useState(false);

  useEffect(() => {
    const { platform } = window.navigator;

    setIsMac(platform.indexOf('Mac') > -1);
    setIsWin(platform.indexOf('Win') > -1);
  }, []);

  return { isMac, isWin };
}

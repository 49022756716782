import { takeLatest, call, put } from 'redux-saga/effects';
import { navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import * as AuthActions from './slice';
import * as AuthAPIs from './api';
import { showError } from '../utils/saga';

const cookies = new Cookies();

export function* signInWorker({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(AuthAPIs.signIn, { email, password });
    const { idToken, refreshToken, username } = response.data;

    // TODO: Store into cookies
    cookies.set('idToken', idToken, { path: '/' });
    cookies.set('refreshToken', refreshToken, { path: '/' });
    cookies.set('username', username, { path: '/' });

    yield put(AuthActions.signInSuccess(response.data));

    // Fix language issue
    const language = window.localStorage.getItem('gatsby-i18next-language');

    navigate(
      // eslint-disable-next-line prettier/prettier
      payload?.from?.pathname || `${language && language !== 'en' ? `/${language}` : ''}/dashboard`
    );
  } catch (err) {
    yield put(AuthActions.signInFailure(err));
    showError(err);
  }
}

export function* renewTokenWorker() {
  try {
    const username = cookies.get('username');
    const refreshToken = cookies.get('refreshToken');

    const response = yield call(AuthAPIs.renewToken, {
      refreshToken,
      email: username,
    });
    const {
      idToken: newIdToken,
      refreshToken: newRefreshToken,
    } = response.data;
    cookies.set('idToken', newIdToken, { path: '/' });
    cookies.set('refreshToken', newRefreshToken, { path: '/' });
    yield put(AuthActions.renewTokenSuccess(response.data));
  } catch (err) {
    yield put(AuthActions.renewTokenFailure(err));
    yield put(AuthActions.signOut());
  }
}

export function* signUpWorker({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(AuthAPIs.signUp, { email, password });
    yield put(AuthActions.signUpSuccess(response));

    // Fix language issue
    const language = window.localStorage.getItem('gatsby-i18next-language');

    navigate(
      // eslint-disable-next-line prettier/prettier
      `${language && language !== 'en' ? `/${language}` : ''}/auth/verify-email?email=${encodeURIComponent(email)}`
    );
  } catch (err) {
    yield put(AuthActions.signUpFailure(err));
    showError(err);
  }
}

export function* verifyEmailWorker({ payload }) {
  try {
    const response = yield call(AuthAPIs.verifyEmail, payload);
    yield put(AuthActions.verifyEmailSuccess(response));

    // Fix language issue
    const language = window.localStorage.getItem('gatsby-i18next-language');
    navigate(
      `${language && language !== 'en' ? `/${language}` : ''}/auth/sign-in`
    );
  } catch (err) {
    yield put(AuthActions.verifyEmailFailure(err));
    showError(err);
  }
}

export function* forgotPasswordWorker({ payload }) {
  try {
    const response = yield call(AuthAPIs.forgotPassword, payload);
    yield put(AuthActions.forgotPasswordSuccess(response));
    const { email } = payload;

    // Fix language issue
    const language = window.localStorage.getItem('gatsby-i18next-language');
    navigate(
      // eslint-disable-next-line prettier/prettier
      `${language && language !== 'en' ? `/${language}` : ''}/auth/verify-password?email=${encodeURIComponent(email)}`
    );
  } catch (err) {
    yield put(AuthActions.forgotPasswordFailure(err));
    showError(err);
  }
}

export function* verifyPasswordWorker({ payload }) {
  try {
    const { email, code, newPassword } = payload;
    yield call(AuthAPIs.verifyPassword, {
      email,
      code,
      newPassword,
    });
    yield put(AuthActions.verifyPasswordSuccess());

    // Fix language issue
    const language = window.localStorage.getItem('gatsby-i18next-language');
    navigate(
      `${language && language !== 'en' ? `/${language}` : ''}/auth/sign-in`
    );
  } catch (err) {
    yield put(AuthActions.verifyPasswordFailure(err));
    showError(err);
  }
}

export default [
  takeLatest(AuthActions.signIn, signInWorker),
  takeLatest(AuthActions.renewToken, renewTokenWorker),
  takeLatest(AuthActions.signUp, signUpWorker),
  takeLatest(AuthActions.verifyEmail, verifyEmailWorker),
  takeLatest(AuthActions.forgotPassword, forgotPasswordWorker),
  takeLatest(AuthActions.verifyPassword, verifyPasswordWorker),
];

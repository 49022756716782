import { takeLatest, call, put } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as TestCaseActions from './slice';
import * as APIs from '../Projects/api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

// TODO: Get list test cases
export function* getTestCasesWorker({
  payload: { id, $refresh },
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const response = yield call(apiCaller, APIs.getTestCases, { id });
    const { ids, data } = convertGetAllData(response.data);
    const result = {
      containerId: id,
      ids,
      data,
      $refresh,
    };
    yield put(TestCaseActions.getTestCasesSuccess(result));
    yield resolve(result);
  } catch (err) {
    yield put(TestCaseActions.getTestCasesFailure({ ...err, containerId: id }));
    showError(err);
    yield reject(err);
  }
}

// TODO: Get test case detail
export function* getTestCaseWorker({
  payload: { id },
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.getTestCase, { id });
    yield put(TestCaseActions.getTestCaseDetailSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestCaseActions.getTestCaseDetailFailure(err));
    showError(err);
    reject(err);
  }
}

// TODO: Update test case
export function* updateTestCaseWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.updateTestCase, payload);
    yield put(TestCaseActions.updateTestCaseSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestCaseActions.updateTestCaseFailure(err));
    showError(err);
    reject(err);
  }
}

// TODO: Create a new test case
export function* createTestCaseWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.createTestCase, payload);
    yield put(
      TestCaseActions.createTestCaseSuccess({
        ...data,
        containerId: payload.id,
      })
    );
    resolve(data);
  } catch (err) {
    yield put(TestCaseActions.createTestCaseFailure(err));
    showError(err);
    reject(err);
  }
}

export function* deleteTestCaseWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.deleteTestCase, payload);
    yield put(TestCaseActions.deleteTestCaseSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestCaseActions.deleteTestCaseFailure(err));
    reject(err);
    showError(err);
  }
}

export default [
  takeLatest(TestCaseActions.getTestCases, getTestCasesWorker),
  takeLatest(TestCaseActions.getTestCaseDetail, getTestCaseWorker),
  takeLatest(TestCaseActions.updateTestCase, updateTestCaseWorker),
  takeLatest(TestCaseActions.createTestCase, createTestCaseWorker),
  takeLatest(TestCaseActions.deleteTestCase, deleteTestCaseWorker),
];

import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';
import { quitSessionDone } from 'redux/Inspector/slice';

const initialState = {
  current: {},
  data: {},
  ids: [],
  loading: false,
  containers: {},
};

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // TODO: GET ALL
    getFiles(state) {
      state.loading = true;
    },
    getFilesSuccess(state, { payload: { ids, data, $refresh } }) {
      state.ids = $refresh ? ids : union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getFilesFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getFileDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getFileDetailSuccess(state, { payload }) {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
      state.loading = false;
    },
    getFileDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE
    createFile(state) {
      state.loading = true;
    },
    createFileSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
      state.loading = false;
    },
    createFileFailure(state) {
      state.loading = false;
    },
    // TODO: UPDATE
    updateFile(state) {
      state.loading = true;
    },
    updateFileSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = [...state.ids, payload.id];
      state.loading = false;
    },
    updateFileFailure(state) {
      state.loading = true;
    },
    // TODO: DELETE
    deleteFile(state) {
      state.loading = true;
    },
    deleteFileSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteFileFailure(state) {
      state.loading = false;
    },
    removeCurrentFile(state) {
      state.currentId = null;
    },
  },
  extraReducers: {
    [quitSessionDone]: () => initialState,
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getFiles,
  getFilesSuccess,
  getFilesFailure,
  getFileDetail,
  getFileDetailSuccess,
  getFileDetailFailure,
  createFile,
  createFileSuccess,
  createFileFailure,
  updateFile,
  updateFileSuccess,
  updateFileFailure,
  deleteFile,
  deleteFileSuccess,
  deleteFileFailure,
  removeCurrentFile,
} = actions;

export default reducer;

import { postApi2 } from '../utils/api';

export async function signIn(data) {
  return postApi2('auth/sign-in', data);
}

export async function renewToken(data) {
  return postApi2('auth/renew-token', data);
}

export async function signUp(data) {
  return postApi2('auth/sign-up', data);
}

export async function verifyEmail(data) {
  return postApi2('auth/verify-email', data);
}

export async function forgotPassword(data) {
  return postApi2('auth/forgot-password', data);
}

export async function verifyPassword(data) {
  return postApi2('auth/verify-password', data);
}

import { getApi2, delApi2 } from '../utils/api';

export async function getDeviceResults(paths) {
  return getApi2(`jobs/${paths.jobId}/device-results`);
}

export async function getDeviceResult(data) {
  return getApi2(`device-results/${data.id}`);
}

export async function deleteDeviceResult(data) {
  return delApi2(`device-results/${data.id}`, data);
}

import { combineReducers } from 'redux';
import inspector from './Inspector/slice';
import shared from './shared/reducers';
import auth, { signOutSuccess } from './Auth/slice';
import projects from './Projects/slice';
import testSuites from './TestSuites/slice';
import testCases from './TestCases/slice';
import files from './Files/slice';
import jobs from './Jobs/slice';
import clientDevices from './ClientDevices/slice';
import appleTeams from './AppleTeams/slice';
import deviceResults from './DeviceResults/slice';
import testCaseResults from './TestCaseResults/slice';

const createRootReducer = (state, action) => {
  if (action.type === signOutSuccess.type) {
    localStorage.removeItem('persist:root');

    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  const appReducer = combineReducers({
    auth,
    inspector,
    shared,
    projects,
    testSuites,
    testCases,
    files,
    jobs,
    clientDevices,
    appleTeams,
    deviceResults,
    testCaseResults,
  });

  return appReducer(state, action);
};

export default createRootReducer;

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const isBrowser = typeof window !== `undefined`;

export const isSignedIn = () => {
  if (!isBrowser) return false;

  const idToken = cookies.get('idToken');

  return !!idToken;
};

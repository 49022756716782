import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import * as Actions from './slice';
import * as APIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

export function* getTestCaseResultsWorker({ payload: { deviceResultId } }) {
  try {
    const response = yield call(apiCaller, APIs.getTestCaseResults, {
      deviceResultId,
    });

    const { ids, data } = convertGetAllData(response.data);
    yield put(
      Actions.getTestCaseResultsSuccess({
        containerId: deviceResultId,
        ids,
        data,
      })
    );
  } catch (err) {
    yield put(Actions.getTestCaseResultsFailure(err));
    showError(err);
  }
}

export function* getTestCaseResultWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, APIs.getTestCaseResult, payload);
    yield put(Actions.getTestCaseResultDetailSuccess(data));
  } catch (err) {
    yield put(Actions.getTestCaseResultDetailFailure(err));
    showError(err);
  }
}

export function* deleteTestCaseResultWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, APIs.deleteTestCaseResult, payload);
    yield put(Actions.deleteTestCaseResultSuccess(data));
  } catch (err) {
    yield put(Actions.deleteTestCaseResultFailure(err));
    showError(err);
  }
}

export default [
  takeEvery(Actions.getTestCaseResults, getTestCaseResultsWorker),
  takeLatest(Actions.getTestCaseResultDetail, getTestCaseResultWorker),
  takeLatest(Actions.deleteTestCaseResult, deleteTestCaseResultWorker),
];

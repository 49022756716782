import { createSlice } from '@reduxjs/toolkit';
import { watchWebSocket } from 'redux/WebSocket/slice';
import { watchLocalWebSocket } from 'redux/WebSocketLocal/slice';

const slice = createSlice({
  name: 'app',
  initialState: {
    clientId: null,
  },
  reducers: {},
});

// Extract the action creators object and the reducer
const { reducer } = slice;

// TODO: Thunks
export const startUp = () => (dispatch) => {
  // TODO: API Gateway WebSocket
  dispatch(watchWebSocket());

  // TODO: Local WebSocket
  dispatch(watchLocalWebSocket());
};

export default reducer;

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import onClientEntry from './src/utils/why-did-you-render';
import wrapRootElement from './src/redux/wrap-with-provider';

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function onRouteUpdate(location, mainNavHeight = 64) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop;

    window.scrollTo({
      top: item - mainNavHeight,
      // behavior: 'smooth',
    });
  }

  return true;
}

export { onClientEntry, wrapRootElement, onRouteUpdate };

// exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);

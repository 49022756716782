import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';

const slice = createSlice({
  name: 'testCaseResults',
  initialState: {
    current: {},
    data: {},
    ids: [],
    loading: false,
    containers: {},
  },
  reducers: {
    // TODO: GET ALL
    getTestCaseResults(state) {
      state.loading = true;
    },
    getTestCaseResultsSuccess(state, { payload: { containerId, ids, data } }) {
      state.containers[containerId] = ids;
      state.ids = union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getTestCaseResultsFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getTestCaseResultDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getTestCaseResultDetailSuccess(state, { payload }) {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
      state.loading = false;
    },
    getTestCaseResultDetailFailure(state) {
      state.loading = false;
    },
    // TODO: DELETE
    deleteTestCaseResult(state) {
      state.loading = true;
    },
    deleteTestCaseResultSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteTestCaseResultFailure(state) {
      state.loading = false;
    },
    removeCurrentResult(state) {
      state.currentId = null;
    },
    receiveTestCaseResult(state, { payload }) {
      state.containers[payload.deviceResultId] = union(
        state.containers[payload.deviceResultId],
        [payload.id]
      );
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getTestCaseResults,
  getTestCaseResultsSuccess,
  getTestCaseResultsFailure,
  getTestCaseResultDetail,
  getTestCaseResultDetailSuccess,
  getTestCaseResultDetailFailure,
  deleteTestCaseResult,
  deleteTestCaseResultSuccess,
  deleteTestCaseResultFailure,
  removeCurrentTestCaseResult,
  receiveTestCaseResult,
} = actions;

export default reducer;

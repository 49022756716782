import { all } from 'redux-saga/effects';
import authSagas from './Auth/sagas';
import projectSagas from './Projects/sagas';
import testSuiteSagas from './TestSuites/sagas';
import testCaseSagas from './TestCases/sagas';
import fileSagas from './Files/sagas';
import jobSagas from './Jobs/sagas';
import wsSagas from './WebSocket/sagas';
import wsLocalSagas from './WebSocketLocal/sagas';
import inspectorSagas from './Inspector/sagas';
import appleTeams from './AppleTeams/sagas';
import deviceResultSagas from './DeviceResults/sagas';
import testCaseResultSagas from './TestCaseResults/sagas';

export default function* root() {
  yield all([
    ...authSagas,
    ...projectSagas,
    ...testSuiteSagas,
    ...testCaseSagas,
    ...fileSagas,
    ...jobSagas,
    ...wsSagas,
    ...wsLocalSagas,
    ...inspectorSagas,
    ...appleTeams,
    ...deviceResultSagas,
    ...testCaseResultSagas,
  ]);
}

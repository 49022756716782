import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import uniq from 'lodash/uniq';
import union from 'lodash/union';

const slice = createSlice({
  name: 'testCases',
  initialState: {
    current: {},
    data: {},
    ids: [],
    loading: false,
    containers: {},
    loads: {},
  },
  reducers: {
    // TODO: GET ALL
    getTestCases(state, { payload: { id } }) {
      state.loading = true;
      state.loads[id] = true;
    },
    getTestCasesSuccess(
      state,
      { payload: { containerId, ids, data, $refresh } }
    ) {
      state.ids = $refresh ? ids : union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.containers = {
        ...state.containers,
        [containerId]: ids,
      };
      state.loading = false;
      state.loads[containerId] = false;
    },
    getTestCasesFailure(state, { payload: { containerId } }) {
      state.loading = false;
      state.loads[containerId] = false;
    },
    // TODO: DETAIL
    getTestCaseDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getTestCaseDetailSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    getTestCaseDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE AND UPDATE TEST SUITE
    createTestCase(state) {
      state.loading = true;
    },
    createTestCaseSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = uniq([...state.ids, payload.id]);
      state.containers[payload.containerId] = uniq([
        ...(state.containers?.[payload.containerId] ?? []),
        payload.id,
      ]);
      state.loading = false;
    },
    createTestCaseFailure(state) {
      state.loading = false;
    },
    updateTestCase(state) {
      state.loading = true;
    },
    updateTestCaseSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    updateTestCaseFailure(state) {
      state.loading = false;
    },
    deleteTestCase(state) {
      state.loading = true;
    },
    deleteTestCaseSuccess(state, { payload }) {
      state.currentId = null;
      state.containers = {};
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteTestCaseFailure(state) {
      state.loading = false;
    },
    removeCurrentTestCase(state) {
      state.currentId = null;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getTestCases,
  getTestCasesSuccess,
  getTestCasesFailure,
  getTestCaseDetail,
  getTestCaseDetailSuccess,
  getTestCaseDetailFailure,
  createTestCase,
  createTestCaseSuccess,
  createTestCaseFailure,
  updateTestCase,
  updateTestCaseSuccess,
  updateTestCaseFailure,
  deleteTestCase,
  deleteTestCaseSuccess,
  deleteTestCaseFailure,
  removeCurrentTestCase,
} = actions;

export default reducer;

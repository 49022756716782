import { getApi2, postApi2, putApi2, delApi2 } from '../utils/api';

export async function getJobs({ id }, filters) {
  return getApi2(`test-suites/${id}/jobs`, filters);
}

export async function createJob(paths, data) {
  return postApi2(`test-suites/${paths.testSuiteId}/jobs`, data);
}

export async function getJob(data) {
  return getApi2(`jobs/${data.id}`);
}

export async function updateJob(paths, data) {
  return putApi2(`jobs/${paths.id}`, data);
}

export async function deleteJob(data) {
  return delApi2(`jobs/${data.id}`, data);
}

export async function getTestSuites(data) {
  return getApi2(`jobs/${data.id}/test-suites`);
}

export async function buildJob({ id }) {
  return postApi2(`jobs/${id}/builds`);
}

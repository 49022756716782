import { call, put, race, take } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  renewToken,
  renewTokenSuccess,
  renewTokenFailure,
} from 'redux/Auth/slice';
import exception from '../shared/Exception';

export const showError = (error) => {
  const keys = Object.keys(exception);
  const index = keys.findIndex((key) => error.exception === key);
  if (index && index < 0) {
    notification.error({
      message: 'Notification',
      description: exception.SomethingWentWrong,
    });
  } else {
    notification.error({
      message: 'Notification',
      description: exception[keys[index]],
    });
  }
};

export function* apiCaller(apiFunc, ...params) {
  try {
    const response = yield call(apiFunc, ...params);
    return response;
  } catch (error) {
    if (error.exception === 'Unauthorized') {
      yield put(renewToken());
      // TODO: Wait until renewing token success or failure
      const { success } = yield race({
        success: take(renewTokenSuccess),
        failure: take(renewTokenFailure),
      });

      if (success) {
        return yield call(apiFunc, ...params);
      }
    }

    throw error;
  }
}

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import React from 'react';

export default function onClientEntry() {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      trackHooks: true,
      trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
      exclude: [
        /^Body/,
        /^undefined/,
        /^Checkbox/,
        /^Radio/,
        /^Table/,
        /^Cell/,
        /^Handle/,
        /^BaseHandle/,
        /^EdgeWrapper/,
        /^EdgeRenderer/,
        /^NodeWrapper/,
        /^FlowRenderer/,
        /^Controls/,
        /^MiniMap/,
        /^GraphView/,
        /^Unknown/,
      ],
    });
  }
}

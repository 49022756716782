export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

/**
 * TODO: Change language
 */
export function changeLanguage(language) {
  return (dispatch) => {
    dispatch({ type: CHANGE_LANGUAGE, language });
  };
}

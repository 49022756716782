import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';

const slice = createSlice({
  name: 'projects',
  initialState: {
    current: {},
    data: {},
    ids: [],
    loading: false,
    containers: {},
  },
  reducers: {
    // TODO: GET ALL
    getProjects(state) {
      state.loading = true;
    },
    getProjectsSuccess(state, { payload: { ids, data } }) {
      state.ids = union(state.ids, ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getProjectsFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getProjectDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getProjectDetailSuccess(state, { payload }) {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
      state.loading = false;
    },
    getProjectDetailFailure(state) {
      state.loading = false;
    },
    // TODO: CREATE
    createProject(state) {
      state.loading = true;
    },
    createProjectSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.ids = union(state.ids, [payload.id]);
      state.loading = false;
    },
    createProjectFailure(state) {
      state.loading = false;
    },
    // TODO: UPDATE
    updateProject(state) {
      state.loading = true;
    },
    updateProjectSuccess(state, { payload }) {
      state.data[payload.id] = payload;
      state.loading = false;
    },
    updateProjectFailure(state) {
      state.loading = true;
    },
    // TODO: DELETE
    deleteProject(state) {
      state.loading = true;
    },
    deleteProjectSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteProjectFailure(state) {
      state.loading = false;
    },
    removeCurrentProject(state) {
      state.currentId = null;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  getProjectDetail,
  getProjectDetailSuccess,
  getProjectDetailFailure,
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
  removeCurrentProject,
} = actions;

export default reducer;

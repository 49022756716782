import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';

function DeviceTypeCell({ label, color, loading, tooltip }) {
  const { t } = useTranslation();
  const loadingIcon = loading ? <SyncOutlined spin /> : null;

  return (
    <Tooltip title={tooltip}>
      <Tag icon={loadingIcon} color={color} key={label}>
        {t(`${label}`)}
      </Tag>
    </Tooltip>
  );
}

DeviceTypeCell.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  loading: PropTypes.bool,
  tooltip: PropTypes.string,
};

DeviceTypeCell.defaultProps = {
  color: 'geekblue',
};

export default DeviceTypeCell;

import { takeLatest, put, take } from 'redux-saga/effects';
import { showError } from 'redux/utils/saga';
import { getFileDetail } from 'redux/Files/slice';
import { DEFERRED } from 'redux/deferredMiddleware';
import {
  getTestSuiteDetail,
  getTestSuiteDetailSuccess,
} from 'redux/TestSuites/slice';
import {
  getTestCaseDetail,
  getTestCaseDetailSuccess,
} from 'redux/TestCases/slice';
import { getInspectorData } from './slice';

function* getInspectorDataWorker({
  payload: { testCaseId },
  [DEFERRED]: { resolve, reject },
}) {
  try {
    yield put(getTestCaseDetail({ id: testCaseId }));

    const {
      payload: { testSuiteId },
    } = yield take(getTestCaseDetailSuccess);

    yield put(getTestSuiteDetail({ id: testSuiteId }));

    const {
      payload: { fileId },
    } = yield take(getTestSuiteDetailSuccess);

    if (fileId) {
      yield put(getFileDetail({ id: fileId }));
    }
  } catch (err) {
    showError(err);
    reject(err);
  }
}

export default [takeLatest(getInspectorData, getInspectorDataWorker)];

import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as TestSuiteActions from './slice';
import * as APIs from '../Projects/api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

// TODO: Create new test suite
export function* createTestSuiteWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.createTestSuite, payload);
    const result = {
      data,
      containerId: payload.id,
    };
    yield put(TestSuiteActions.createTestSuiteSuccess(result));
    resolve(result);
  } catch (err) {
    yield put(TestSuiteActions.createTestSuiteFailure(err));
    showError(err);
    reject(err);
  }
}

// TODO: Get list test suites
export function* getTestSuitesWorker({ payload: { id } }) {
  try {
    const response = yield call(apiCaller, APIs.getTestSuites, { id });
    const { ids, data } = convertGetAllData(response.data);
    const result = { ids, data, containerId: id };
    yield put(TestSuiteActions.getTestSuitesSuccess(result));
  } catch (err) {
    yield put(
      TestSuiteActions.getTestSuitesFailure({ ...err, containerId: id })
    );
    showError(err);
  }
}

// TODO: Get test suite detail
export function* getTestSuiteWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.getTestSuite, payload);
    yield put(TestSuiteActions.getTestSuiteDetailSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestSuiteActions.getTestSuiteDetailFailure(err));
    showError(err);
    reject(err);
  }
}

// TODO: Update test suite
export function* updateTestSuiteWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const response = yield call(apiCaller, APIs.updateTestSuite, payload);
    const { data } = response;
    yield put(TestSuiteActions.updateTestSuiteSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestSuiteActions.updateTestSuiteFailure(err));
    showError(err);
    reject(err);
  }
}

export function* deleteTestSuiteWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, APIs.deleteTestSuite, payload);
    yield put(TestSuiteActions.deleteTestSuiteSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(TestSuiteActions.deleteTestSuiteFailure(err));
    showError(err);
    reject(err);
  }
}

export default [
  takeEvery(TestSuiteActions.createTestSuite, createTestSuiteWorker),
  takeEvery(TestSuiteActions.getTestSuites, getTestSuitesWorker),
  takeLatest(TestSuiteActions.getTestSuiteDetail, getTestSuiteWorker),
  takeLatest(TestSuiteActions.updateTestSuite, updateTestSuiteWorker),
  takeLatest(TestSuiteActions.deleteTestSuite, deleteTestSuiteWorker),
];

import { takeLatest, call, put } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as ProjectActions from './slice';
import * as ProjectAPIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

export function* getProjectsWorker() {
  try {
    const response = yield call(apiCaller, ProjectAPIs.getProjects);
    const results = convertGetAllData(response.data);
    yield put(ProjectActions.getProjectsSuccess(results));
  } catch (err) {
    yield put(ProjectActions.getProjectsFailure(err));
    showError(err);
  }
}

export function* createProjectWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(apiCaller, ProjectAPIs.createProject, payload);
    yield put(ProjectActions.createProjectSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(ProjectActions.createProjectFailure(err));
    reject(err);
    showError(err);
  }
}

export function* updateProjectWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, ProjectAPIs.updateProject, payload);
    yield put(ProjectActions.updateProjectSuccess(data));
  } catch (err) {
    yield put(ProjectActions.updateProjectFailure(err));
    showError(err);
  }
}

export function* getProjectWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, ProjectAPIs.getProject, payload);
    yield put(ProjectActions.getProjectDetailSuccess(data));
  } catch (err) {
    yield put(ProjectActions.getProjectDetailFailure(err));
    showError(err);
  }
}

export function* deleteProjectWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, ProjectAPIs.deleteProject, payload);
    yield put(ProjectActions.deleteProjectSuccess(data));
  } catch (err) {
    yield put(ProjectActions.deleteProjectFailure(err));
    showError(err);
  }
}

export default [
  takeLatest(ProjectActions.getProjects, getProjectsWorker),
  takeLatest(ProjectActions.createProject, createProjectWorker),
  takeLatest(ProjectActions.updateProject, updateProjectWorker),
  takeLatest(ProjectActions.getProjectDetail, getProjectWorker),
  takeLatest(ProjectActions.deleteProject, deleteProjectWorker),
];

import { createSlice } from '@reduxjs/toolkit';
import { filterObject } from 'redux/utils/dataProvider';
import union from 'lodash/union';
import { uniq } from 'lodash';

const initialState = {
  current: {},
  data: {},
  ids: [],
  loading: false,
  containers: {},
};

const slice = createSlice({
  name: 'deviceResults',
  initialState,
  reducers: {
    // TODO: GET ALL
    getDeviceResults(state) {
      state.loading = true;
    },
    getDeviceResultsSuccess(state, { payload: { containerId, ids, data } }) {
      state.containers[containerId] = ids;
      state.ids = union(ids, state.ids);
      state.data = { ...state.data, ...data };
      state.loading = false;
    },
    getDeviceResultsFailure(state) {
      state.loading = false;
    },
    // TODO: DETAIL
    getDeviceResultDetail(state, { payload: { id } }) {
      state.currentId = id;
      state.loading = true;
    },
    getDeviceResultDetailSuccess(state, { payload }) {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
      state.loading = false;
    },
    getDeviceResultDetailFailure(state) {
      state.loading = false;
    },
    // TODO: DELETE
    deleteDeviceResult(state) {
      state.loading = true;
    },
    deleteDeviceResultSuccess(state, { payload }) {
      state.currentId = null;
      state.data = filterObject(state.data, (key) => key !== payload.id);
      state.ids = state.ids.filter((id) => id !== payload.id);
      state.loading = false;
    },
    deleteDeviceResultFailure(state) {
      state.loading = false;
    },
    removeCurrentResult(state) {
      state.currentId = null;
    },
    resetDeviceResults() {
      return initialState;
    },
    receiveDeviceResult(state, { payload }) {
      if (!state.containers[payload.jobId]) {
        state.containers[payload.jobId] = [];
      }
      state.containers[payload.jobId] = uniq([
        ...state.containers[payload.jobId],
        payload.id,
      ]);
      state.data[payload.id] = payload;
      state.ids = uniq([...state.ids, payload.id]);
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const {
  getDeviceResults,
  getDeviceResultsSuccess,
  getDeviceResultsFailure,
  getDeviceResultDetail,
  getDeviceResultDetailSuccess,
  getDeviceResultDetailFailure,
  deleteDeviceResult,
  deleteDeviceResultSuccess,
  deleteDeviceResultFailure,
  removeCurrentResult,
  resetDeviceResults,
  receiveDeviceResult,
} = actions;

export default reducer;

import { getApi2, delApi2 } from '../utils/api';

export async function getTestCaseResults(paths) {
  return getApi2(`device-results/${paths.deviceResultId}/test-case-results`);
}

export async function getTestCaseResult(paths) {
  return getApi2(`test-case-results/${paths.deviceResultId}`);
}

export async function deleteTestCaseResult(paths) {
  return delApi2(`test-case-results/${paths.deviceResultId}`);
}

import { takeLatest, call, put } from 'redux-saga/effects';
import { DEFERRED } from 'redux/deferredMiddleware';
import * as AppleTeamActions from './slice';
import * as AppleTeamAPIs from './api';
import { showError, apiCaller } from '../utils/saga';
import { convertGetAllData } from '../utils/dataProvider';

export function* getAppleTeamsWorker() {
  try {
    const response = yield call(apiCaller, AppleTeamAPIs.getAppleTeams);
    const results = convertGetAllData(response.data, 'teamId');
    yield put(AppleTeamActions.getAppleTeamsSuccess(results));
  } catch (err) {
    yield put(AppleTeamActions.getAppleTeamsFailure(err));
    showError(err);
  }
}

export function* createAppleTeamWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(
      apiCaller,
      AppleTeamAPIs.createAppleTeam,
      payload
    );
    yield put(AppleTeamActions.createAppleTeamSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(AppleTeamActions.createAppleTeamFailure(err));
    reject(err);
    showError(err);
  }
}

export function* updateAppleTeamWorker({
  payload,
  [DEFERRED]: { resolve, reject },
}) {
  try {
    const { data } = yield call(
      apiCaller,
      AppleTeamAPIs.updateAppleTeam,
      payload
    );
    yield put(AppleTeamActions.updateAppleTeamSuccess(data));
    resolve(data);
  } catch (err) {
    yield put(AppleTeamActions.updateAppleTeamFailure(err));
    showError(err);
    reject(err);
  }
}

export function* getAppleTeamWorker({ payload }) {
  try {
    const { data } = yield call(apiCaller, AppleTeamAPIs.getAppleTeam, payload);
    yield put(AppleTeamActions.getAppleTeamDetailSuccess(data));
  } catch (err) {
    yield put(AppleTeamActions.getAppleTeamDetailFailure(err));
    showError(err);
  }
}

export function* deleteAppleTeamWorker({ payload }) {
  try {
    const { data } = yield call(
      apiCaller,
      AppleTeamAPIs.deleteAppleTeam,
      payload
    );
    yield put(AppleTeamActions.deleteAppleTeamSuccess(data));
  } catch (err) {
    yield put(AppleTeamActions.deleteAppleTeamFailure(err));
    showError(err);
  }
}

export default [
  takeLatest(AppleTeamActions.getAppleTeams, getAppleTeamsWorker),
  takeLatest(AppleTeamActions.createAppleTeam, createAppleTeamWorker),
  takeLatest(AppleTeamActions.updateAppleTeam, updateAppleTeamWorker),
  takeLatest(AppleTeamActions.getAppleTeamDetail, getAppleTeamWorker),
  takeLatest(AppleTeamActions.deleteAppleTeam, deleteAppleTeamWorker),
];

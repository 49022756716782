import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import * as APIs from './api';

const cookies = new Cookies();

const slice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    signIn(state) {
      state.signInLoading = true;
    },
    signInSuccess(state) {
      state.signInLoading = false;
    },
    signInFailure(state) {
      state.signInLoading = false;
    },
    renewToken(state) {
      state.renewTokenLoading = true;
    },
    renewTokenSuccess(state) {
      state.renewTokenLoading = false;
    },
    renewTokenFailure(state) {
      state.renewTokenLoading = false;
    },
    signOutSuccess() {
      // TODO: Used in root reducer
    },
    signUp(state, { payload: { email } }) {
      state.signUploading = true;
      state.email = email;
    },
    signUpSuccess(state) {
      state.signUpLoading = false;
    },
    signUpFailure(state) {
      state.signUploading = false;
    },
    verifyEmail(state) {
      state.verifyEmailLoading = true;
    },
    verifyEmailSuccess(state) {
      state.verifyEmailLoading = false;
      state.email = null;
    },
    verifyEmailFailure(state) {
      state.verifyEmailLoading = false;
    },
    forgotPassword(state, { payload: { email } }) {
      state.forgotPasswordLoading = true;
      state.email = email;
    },
    forgotPasswordSuccess(state) {
      state.forgotPasswordLoading = false;
    },
    forgotPasswordFailure(state) {
      state.forgotPasswordLoading = false;
    },
    verifyPassword(state) {
      state.verifyPasswordLoading = true;
    },
    verifyPasswordSuccess(state) {
      state.verifyPasswordLoading = false;
      state.email = null;
    },
    verifyPasswordFailure(state) {
      state.verifyPasswordLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = slice;

export const signOut = () => (dispatch) => {
  // TODO: Used in root reducer
  // Fix language issue
  const language = window.localStorage.getItem('gatsby-i18next-language');
  navigate(
    `${language && language !== 'en' ? `/${language}` : ''}/auth/sign-in`
  );

  cookies.remove('idToken', { path: '/' });
  cookies.remove('refreshToken', { path: '/' });
  cookies.remove('username', { path: '/' });

  dispatch(actions.signOutSuccess());
};

export const getIdToken = async () => {
  const idToken = cookies.get('idToken');

  if (!idToken) return null;
  const { exp } = jwtDecode(idToken);
  const expiresIn = exp * 1000 - Date.now();
  // If id token allowed if it has more than 10 minutes before expiring.
  if (expiresIn > 10 * 60 * 1000) {
    return idToken;
  }
  // Renew Token
  try {
    const {
      data: { idToken: newIdToken, refreshToken: newRefreshToken },
    } = await APIs.renewToken({
      refreshToken: cookies.get('refreshToken'),
      email: cookies.get('username'),
    });

    cookies.set('idToken', newIdToken, { path: '/' });
    cookies.set('refreshToken', newRefreshToken, { path: '/' });

    return newIdToken;
  } catch (e) {
    // Because urlProvider of reconnecting-websocket currently
    // does not handle error, show I will return null here.
    console.error('Url provider error:', e);
    return null;
  }
};

export const {
  signIn,
  signInSuccess,
  signInFailure,
  renewToken,
  renewTokenSuccess,
  renewTokenFailure,
  signUp,
  signUpSuccess,
  signUpFailure,
  verifyEmail,
  verifyEmailSuccess,
  verifyEmailFailure,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyPassword,
  verifyPasswordSuccess,
  verifyPasswordFailure,
  signOutSuccess,
} = actions;

export default reducer;

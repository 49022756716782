// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-client-js": () => import("./../../../src/pages/auth/client.js" /* webpackChunkName: "component---src-pages-auth-client-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-auth-sign-in-js": () => import("./../../../src/pages/auth/sign-in.js" /* webpackChunkName: "component---src-pages-auth-sign-in-js" */),
  "component---src-pages-auth-sign-up-js": () => import("./../../../src/pages/auth/sign-up.js" /* webpackChunkName: "component---src-pages-auth-sign-up-js" */),
  "component---src-pages-auth-verify-email-js": () => import("./../../../src/pages/auth/verify-email.js" /* webpackChunkName: "component---src-pages-auth-verify-email-js" */),
  "component---src-pages-auth-verify-password-js": () => import("./../../../src/pages/auth/verify-password.js" /* webpackChunkName: "component---src-pages-auth-verify-password-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-inspector-index-js": () => import("./../../../src/pages/dashboard/inspector/index.js" /* webpackChunkName: "component---src-pages-dashboard-inspector-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}


import { memoize } from 'lodash';
import {
  currentDataSelectorFactory,
  loadingByContainerIdSelectorFactory,
  itemsByContainerIdSelectorFactory,
  loadingSelectorFactory,
} from 'redux/selectors';
import { createSelector } from 'reselect';

const KEY = 'testSuites';

export const currentTestSuiteSelector = currentDataSelectorFactory(KEY);

export const currentTestSuiteLoadingSelector = loadingSelectorFactory(KEY);

export const testSuiteLoadingByProjectIdSelector = loadingByContainerIdSelectorFactory(
  KEY
);

export const testSuitesByProjectIdSelector = createSelector(
  itemsByContainerIdSelectorFactory(KEY),
  (itemsByContainerIdFilter) =>
    memoize((projectId) => itemsByContainerIdFilter(projectId))
);
